import { useLoaderData } from "react-router-dom"
import { Slot } from "../../slotData"
import { Container } from "react-bootstrap"
import { getPartneredCasinos } from "../../casinoData"
import { CasinoBanner } from "../../components/casinoBanner/CasinoBanner"
import { Info } from "../../components/info/Info"
import { Helmet } from "react-helmet"

export const SlotView = () => {
    const slot = useLoaderData() as Slot

    if (!slot) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{`${slot.name} Spēļu Automāts 🎖️ Kazino un Kazino`}</title>
                <meta
                    name="description"
                    content="Izmēģini spēļu automātus bez maksas! Atklāj 2024. gada labākos bonusus un ekskluzīvus piedāvājumus, lai uzlabotu savu spēles pieredzi un palielinātu laimestus."
                />
            </Helmet>
            <Container className="h-100">
                <h3>{slot.name}</h3>
                <iframe title={slot.id} src={slot.demoUrl} allow="autoplay; fullscreen" className="w-100 overflow-hidden" style={{ height: "75vh" }} />
                <br />
                <br />
            </Container>
            <Info>
                <>
                    <h1>{`Izmēģini ${slot.name} šajos kazino:`}</h1>
                    {getPartneredCasinos()
                        .slice(0, 3)
                        .map((casino) => {
                            return <CasinoBanner casino={casino} theme="dark" key={casino.id} />
                        })}
                </>
            </Info>
        </>
    )
}
