import { Badge, Button, Card, Col, Row } from "react-bootstrap"
import { DataBox } from "../dataBox/DataBox"
import "./SlotCard.css"
import ReactGA from "react-ga4"
import { Slot } from "../../slotData"

interface SlotCardProps {
    slot: Slot
}

export const SlotCard = (props: SlotCardProps) => {
    const { slot } = props

    const onClickSlot = () => {
        ReactGA.event({
            category: "Promotion",
            action: "clicked_slot",
            label: slot.id,
            value: 1,
        })
    }

    return (
        <Card className="slotContainer" itemScope itemType="https://schema.org/Game">
            <a
                className="imageContainer d-flex flex-row align-items-start rounded-top text-decoration-none"
                href={slot.overviewUrl}
                style={{ backgroundImage: `url(${slot.logoUrl})` }}
                itemProp="url"
            >
                {slot.isNew && (
                    <Badge bg="primary" className="me-auto m-2 d-flex align-items-center" itemProp="new">
                        Jauns
                    </Badge>
                )}
                {slot.isPopular && (
                    <Badge bg="success" className="me-auto m-2 d-flex align-items-center" itemProp="popular">
                        Populārs
                    </Badge>
                )}
            </a>
            <Card.Body>
                <Row className="text-center">
                    <span>
                        <small>{slot.name}</small>
                    </span>
                </Row>
                <Row>
                    {slot.rtp !== undefined && (
                        <Col itemProp="rtp">
                            <DataBox mainText={slot.rtp.toString() + "%"} comment="RTP" />
                        </Col>
                    )}
                </Row>
                <Row className="text-center">
                    <Col>
                        <a href={slot.overviewUrl} itemProp="url">
                            <Button variant={"success"} className="w-100 mt-3" onClick={onClickSlot}>
                                Spēlēt!
                            </Button>
                        </a>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
