import { Info } from "../../components/info/Info"
import { Helmet } from "react-helmet"
import { baseTitle, baseUrl } from "../../constants"

export const Error = () => {
    return (
        <>
            <Helmet>
                <title>{baseTitle + " 404"}</title>
                <link rel="canonical" href={baseUrl + "404"} />
            </Helmet>
            <Info>
                <h1 className="text-center">404</h1>
                <p className="text-center">Kļūda.</p>
            </Info>
        </>
    )
}
