export interface Slot {
    id: string
    name: string
    rtp?: number
    logoUrl: string
    overviewUrl: string
    demoUrl: string
    volatility?: number
    vendor: string
    isPopular: boolean
    isNew: boolean
}

export const getPopularSlots = () => {
    const copy = [...slots]

    copy.sort((a, b) => {
        return a.isPopular === b.isPopular ? 0 : a.isPopular ? -1 : 1
    })

    return copy
}

export const getNewSlots = () => {
    const copy = [...slots]

    copy.sort((a, b) => {
        return a.isNew === b.isNew ? 0 : a.isNew ? -1 : 1
    })

    return copy
}

export const getSlots = () => {
    return [...slots]
}

const slots: Slot[] = [
    {
        id: "queen-of-water",
        name: "Queen Of Water",
        logoUrl: "./assets/slots/queen-of-water.png",
        overviewUrl: "/slots/queen-of-water/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=f528d1d12930381425547&gameCode=SlotMachine_QueenOfWater&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: true,
    },
    {
        id: "hell-hot-100",
        name: "Hell Hot 100",
        logoUrl: "./assets/slots/hell-hot-100.png",
        overviewUrl: "/slots/hell-hot-100/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/1d664d23d008a2c288efcd027d8bb6fa",
        isPopular: true,
        isNew: false,
    },
    {
        id: "gates-of-olympus",
        name: "Gates of Olympus",
        logoUrl: "./assets/slots/gates-of-olympus.svg",
        overviewUrl: "/slots/gates-of-olympus/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20olympgate&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DKMr7VJ1SwXUfuW6Y-632MW_r47vKigi_J_2DPSLLlMCU2W26Otp6gG8dsiZLdFep-Po69BZo23f3i7wpQU6jl_sbpeqCYi4DWk8wdz8ToibmssONrYRtpmlQHGaL--EkFVbEhiql8QIB3Oy8qc_CvVLkOATVAAeo_e1bxETPS0NzaFn9onhAQ63MmlpXJgYyGFJZAz5oAfN_J4faN5zfDCy6yeSubQZlfoq0x3hTBEtcdFdvnAdGyg%253D%253D&stylename=evo_greencasino",
        isPopular: true,
        isNew: false,
    },
    {
        id: "sweet-bonanza",
        name: "Sweet Bonanza",
        logoUrl: "./assets/slots/sweet-bonanza.svg",
        overviewUrl: "/slots/sweet-bonanza/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20fruitsw&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3Dd4mu-YXDN49olpeLgTK5c5VHCfAKnMAkkiMujKEMwiYbdjNWrlSTtFNc0CNUtQxv_hBc4y0-6HDr8oAjhbgbErLaor6YPPm5M5kCm-cIjN2pqRR_whKIcJtMN-YBqcmtNza2GZA5-hLBHS9dk9IhqrgAjBd2S5QEmvz_rhRClFui8Ia0FYZ1f7bvoL-5kDQ_ZcZLtiPCAtJA7mm85tlZDsCXcBf98XXrsrmZvcjYKIipbXJTlqaFpw%253D%253D&stylename=evo_greencasino",
        isPopular: true,
        isNew: false,
    },
    {
        id: "book-of-demi-gods-4",
        name: "Book Of Demi Gods 4",
        logoUrl: "./assets/slots/book-of-demi-gods-4.png",
        overviewUrl: "/slots/book-of-demi-gods-4/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=d3e98c5129388e4425048&gameCode=SlotMachine_BookOfDemiGods4&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: false,
    },
    {
        id: "coin-win-hold-the-spin",
        name: "Coin Win: Hold The Spin",
        logoUrl: "./assets/slots/coin-win-hold-the-spin.png",
        overviewUrl: "/slots/coin-win-hold-the-spin/",
        vendor: "Gamzix",
        demoUrl:
            "//cdn-v2.gamzix.com/coin-win-hold-the-spin/v2.1.12/?host=sapp.gamzix.com&port=443&client=desktop&lang=en&home=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DaTq8Bq6v6RyVhJOOmXY5yQCiXRLClpDF71YDEg58yd_zBYrgTygb32Fdo7HG8t_61mutKCMyCCMO5Et4MqwlmPM%253D&sid=pgdjqyrly&wss=1&promoVersion=v3.0.0&slot_core=v0.0.18f&host-promo=prmapp.gamzix.com",
        isPopular: true,
        isNew: false,
    },
    {
        id: "joker-stoker",
        name: "Joker Stoker",
        logoUrl: "./assets/slots/joker-stoker.svg",
        overviewUrl: "/slots/joker-stoker/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/7e1cd0586d1485a815edecabfb75ff97",
        isPopular: true,
        isNew: false,
    },
    {
        id: "queen-of-fire",
        name: "Queen of Fire",
        logoUrl: "./assets/slots/queen-of-fire.png",
        overviewUrl: "/slots/queen-of-fire/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=8f383c0129316fc424369&gameCode=SlotMachine_QueenOfFire&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: false,
    },
    {
        id: "diamonds-hunt",
        name: "Diamonds Hunt",
        logoUrl: "./assets/slots/diamonds-hunt.svg",
        overviewUrl: "/slots/diamonds-hunt/",
        vendor: "Slotopia",
        demoUrl: "//run.evoplay.games/slot/slotopia/diamondshunt/?operator=4775&session=10121550274&window=1&demo=1&s=faaabd3c97bd69cb74022e941a8c8304",
        isPopular: true,
        isNew: false,
    },
    {
        id: "gates-of-olympus-1000",
        name: "Gates of Olympus 1000",
        logoUrl: "./assets/slots/gates-of-olympus-1000.svg",
        overviewUrl: "/slots/gates-of-olympus-1000/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20olympx&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3D3b8uDAzro7KG1d_lPFbY9RFgP4A-aYwKTZLgbtZHRZRbyJCs4TOoXa7E9zGNmdCiN_wrqs8pco8zIscyD_lxQWc5yYueRAM6ECGweR6hPFlZi7iSRO5Hhng4Ep0nn0aJGk2u9JJtkOVtHAWcP36UBCaP8m2hOKlD-h-C3qydO3EDLtvY7kwJcxQeQwVty6hKbk2fKfUiPKq3-W3lX3asiUWzbSBFB14YqKGi4kE0hNmSjxtN_NRlUA%253D%253D&stylename=evo_greencasino",
        isPopular: true,
        isNew: false,
    },
    {
        id: "majestic-wild-buffalo",
        name: "Majestic Wild Buffalo",
        logoUrl: "./assets/slots/majestic-wild-buffalo.png",
        overviewUrl: "/slots/majestic-wild-buffalo/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=0e9da231293739d425678&gameCode=SlotMachine_MajesticWildBuffalo&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: true,
    },
    {
        id: "3x3-hold-the-spin",
        name: "3x3 Hold the Spin",
        logoUrl: "./assets/slots/3x3-hold-the-spin.png",
        overviewUrl: "/slots/3x3-hold-the-spin/",
        vendor: "Gamzix",
        demoUrl:
            "//cdn-v2.gamzix.com/3x3-hold-the-spin/v2.0.7/?host=sapp.gamzix.com&port=443&client=desktop&lang=en&home=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3Dpw3tgI75vXPWGrdm0dnPBeRRnXtJomoIorzlF48zckQLam_pSsXTdvB1GnwTobsCDaqBDt3mK3dtR8IxWWVxu3U%253D&sid=pgdjqyrly&wss=1&promoVersion=v3.0.0&slot_core=v0.0.18f&host-promo=prmapp.gamzix.com",
        isPopular: false,
        isNew: false,
    },
    {
        id: "sugar-rush",
        name: "Sugar Rush",
        logoUrl: "./assets/slots/sugar-rush.svg",
        overviewUrl: "/slots/sugar-rush/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20sugarrush&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DAYdg33DqvKrPweeoLUCM7XzFTESI7N5ZKmx3Xf9Xmjx8ipKh0abyPII1CzDF4csFYHeFtBZ_DM-JiJFoEXDOiHRhruEmuRnWE-ebzfgJ6Qj4bxQ9oaZPMG4Yz4YzUVAr26taYXChwCHXLvyHqwZR4sOQ0ANB3eKVvTcXLHVcD7Hlugza06ZP_lhwUNGznoHRrlTRw4E8dEGkm2mm0V_zrIPhzF4hQqJ1XrG8JesQKBIHAr-VWLZsKw%253D%253D&stylename=evo_greencasino",
        isPopular: false,
        isNew: false,
    },
    {
        id: "starlight-princess-1000",
        name: "Starlight Princess 1000",
        logoUrl: "./assets/slots/starlight-princess-1000.svg",
        overviewUrl: "/slots/starlight-princess-1000/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20starlightx&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DSJvioZ9nY_uiq3EQLD3BCSbDXD4KMPg_iKtEbTikNDekFm6Z9IQ5Fy_E3ZlpqXzTDmjDtah6j73H7iGcic138ouKvRP1qqAqzLTDQKNivzFjL_x4ILEb80IVlGfev9Kd2xYsi3pm-ZEWKfzTClh8TMfijPIcbj9SbkA8T8f4n4GRVWiUU2y5Sawkl0siyPhpf9t2shiJW64mUvgvC6lKmtDdQDAnldlo98WGEZmWqtCGriqmW4jZlA%253D%253D&stylename=evo_greencasino",
        isPopular: false,
        isNew: true,
    },
    {
        id: "2021-hit-slot",
        name: "2021 Hit Slot",
        logoUrl: "./assets/slots/2021-hit-slot.png",
        overviewUrl: "/slots/2021-hit-slot/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/5b0edbc52a6498fd35e258d042c8449a",
        isPopular: false,
        isNew: false,
    },
    {
        id: "book-of-anksunamun-rockways",
        name: "Book of Anksunamun: Rockways",
        logoUrl: "./assets/slots/book-of-anksunamun-rockways.png",
        overviewUrl: "/slots/book-of-anksunamun-rockways/",
        vendor: "Mascot",
        demoUrl: "https://4z575dp4un462cf.mascot.games/",
        isPopular: false,
        isNew: true,
    },
    {
        id: "zeus-vs-hades-gods-of-war",
        name: "Zeus vs Hades - Gods of War",
        logoUrl: "./assets/slots/zeus-vs-hades-gods-of-war.svg",
        overviewUrl: "/slots/zeus-vs-hades-gods-of-war/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs15godsofwar&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3Dq0t4ss0FFc9IApz9DmTwla9q3BTgOszvAIodS6PRr75znZwVCWl8QolOrI-JOi-YnHN0QJgGTi3N8HEcjTNvOeaTdkh72BxuDnpLfTlhPnpRFBX_CvtzFqhWPFLdINpHqWd1yNSr6l5BU9XsG77bUyidSdJ7iHsMx1HbtWoHrHGlj5WFRem84C9y1sO1fdJ2Scspmmr4bjcIqpxI2-DQ6TfM4G-w5yWqVkIo7grAEEZ9B5fwSYkQZw%253D%253D&stylename=evo_greencasino",
        isPopular: false,
        isNew: false,
    },
    {
        id: "super-golden-dragon-inferno",
        name: "Super Golden Dragon Inferno",
        logoUrl: "./assets/slots/super-golden-dragon-inferno.png",
        overviewUrl: "/slots/super-golden-dragon-inferno/",
        vendor: "Betsoft",
        demoUrl:
            "https://wiraon-c2ss.betsoftgaming.com/cwguestlogin.do?bankId=10235&gameId=908&homeUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DKJI0nFwQq8tqGMiuIb74fFePAqvAXuWRSkuRwsL1ndHoopPfPmNLggIhkynu_hJxDhVj_aJJER40ZVvR2uXLSIY%253D&lang=en_US",
        isPopular: false,
        isNew: true,
    },
    {
        id: "ultra-coin-magnetic-bonus-buy",
        name: "Ultra Coin Magnetic Bonus Buy",
        logoUrl: "./assets/slots/ultra-coin-magnetic-bonus-buy.svg",
        overviewUrl: "/slots/ultra-coin-magnetic-bonus-buy/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/ultracoinmagneticbb/?operator=4775&session=10121550296&window=1&demo=1&s=f30287efc41bd0d631ace0ae7c767019",
        isPopular: true,
        isNew: false,
    },
    {
        id: "fruit-super-nova",
        name: "Fruit Super Nova",
        logoUrl: "./assets/slots/fruit-super-nova.png",
        overviewUrl: "/slots/fruit-super-nova/",
        vendor: "Evoplay",
        demoUrl:
            "//run.evoplay.games/slot/evoplay/fruitsupernova/?operator=4775&session=10121550298&window=1&demo=1&s=fc6b0b39e96a15f116b9cbcbd3270e3a",
        isPopular: false,
        isNew: false,
    },
    {
        id: "pho-sho",
        name: "Pho Sho",
        logoUrl: "./assets/slots/pho-sho.png",
        overviewUrl: "/slots/pho-sho/",
        vendor: "Betsoft",
        demoUrl:
            "https://wiraon-c2ss.betsoftgaming.com/cwguestlogin.do?bankId=10235&gameId=890&homeUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DSDbWLRDFpJpPnL-3yw-8RXsqAAAYh2k1UMsQojQ19XOlACxcfEDbgIDLKA6oOc95cSpGLnSDf3bJTkX-g_tDU2A%253D&lang=en_US",
        isPopular: false,
        isNew: false,
    },
    {
        id: "royal-fruits-5-hold-n-link",
        name: "Royal Fruits 5: Hold 'n' Link",
        logoUrl: "./assets/slots/royal-fruits-5-hold-n-link.png",
        overviewUrl: "/slots/royal-fruits-5-hold-n-link/",
        vendor: "NetGame",
        demoUrl:
            "https://games.netgamenv.com/enter.html?game=463&userId=70-78-53-28-43-11&wshost=wss://wss.netgamenv.com&backend=https://api.netgamenv.com&quality=hd&showPromo=true&lang=en",
        isPopular: false,
        isNew: false,
    },
    {
        id: "fortune-five",
        name: "Fortune Five",
        logoUrl: "./assets/slots/fortune-five.png",
        overviewUrl: "/slots/fortune-five/",
        vendor: "Gamebeat",
        demoUrl:
            "https://games.prod.gamebeat.cloud/fortune_five/index.html?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiIzNzBjZjZiZS03NjUxLTQ5OTgtOTJlYi1jM2MyODNiOGEzYzMiLCJpYXQiOjE3MDkxNjgyMzMsImV4cCI6MTcwOTI1NDYzM30.By4egbEOou_1RvrZcOpxwn106TIo9Bkz4EnoiQhcDJYkkFljlxi0xSFiAWKEnIoA6URNcgfVfVhF6A4nI97nCMH6nuirRR3n6ukEG4mZVHJMNQBNGql_JeD7hJgqQ72KDSIh-KEe4SFj7zV7k-jPwaZLlfvio_OUeJ71Ahjbd3mFYpvCppGlRUDH6ozrUlPelm325NFGxZCXdGloDi8_Bz8SZJkAWsZHBJsRmDEnOJ4g4noiYIDE4JhX4fWqrCDjj7YuDlYuOeGcwuOHjd5MGPylmm6lAT_Qavc0Xfz4f1kiLm3nsAUjwaW1CprRPHwFX9d0EWrNdlVeACrQBfs1Jg&endpoint=https%3A%2F%2Fgrapes.prod.gamebeat.cloud&historyEndpoint=default&game=fortune_five&lang=en&returnUrl=https%3A%2F%2Fverdecasino57.com&clientType=desktop",
        isPopular: false,
        isNew: false,
    },
    {
        id: "plinko-rush",
        name: "Plinko Rush",
        logoUrl: "./assets/slots/plinko-rush.png",
        overviewUrl: "/slots/plinko-rush/",
        vendor: "Betsoft",
        demoUrl:
            "https://wiraon-c2ss.betsoftgaming.com/cwguestlogin.do?bankId=10235&gameId=915&homeUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DzKvqxu2WMawdvKM1tGk0x-ZcvQzIMjmnYgthBGo2tz1UJkcKtKKfbqbR2o7h8W7zWPltww32F3jcahcZLg3yxDY%253D&lang=en_US",
        isPopular: false,
        isNew: false,
    },
    {
        id: "wild-phoenix-rises",
        name: "Wild Phoenix Rises",
        logoUrl: "./assets/slots/wild-phoenix-rises.png",
        overviewUrl: "/slots/wild-phoenix-rises/",
        vendor: "Mascot",
        demoUrl: "https://rzw07jucuia6eck.mascot.games/",
        isPopular: false,
        isNew: false,
    },
    {
        id: "lucky-streak-3",
        name: "Lucky Streak 3",
        logoUrl: "./assets/slots/lucky-streak-3.png",
        overviewUrl: "/slots/lucky-streak-3/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/4705ff15a1940f63f32bb2a0fbec0c18",
        isPopular: false,
        isNew: false,
    },
    {
        id: "wondereels",
        name: "WondeReels",
        logoUrl: "./assets/slots/wondereels.png",
        overviewUrl: "/slots/wondereels/",
        vendor: "Platipus",
        demoUrl:
            "https://prod3.platipusgaming.com/psw/game/demo/GAME_REDIRECT?locale=en_US&token=b458152a12144fa7a854efd06958d6f6&game=wondereels&lobby=https://web-launch.gingame.net/redirects?redirect_url=JJg5YMUKhEn8OPKeifcwafw-TswWyW_BjaFRo6zk5tgHz3c95hKWpERw7N-9x8YWWiMLKp-Oyvfpjlbnm10wqCaW3N1g21rR3sslCKIJUlQSPV1VLUEiTckggqtf_i5osuHa_cBU918Q5b0xrwRhVcOJxaclxGb8CSOcYovN9IVTjAm2R-UQi4FdSoR7aW05MNwbNN12w5hqPTUEZQA9RKgNnorI6-d3aWo3fBndoY406HymD8WxLA%3D%3D&options=",
        isPopular: false,
        isNew: true,
    },
    {
        id: "cash-vault-hold-n-link",
        name: "Cash Vault Hold 'n' Link",
        logoUrl: "./assets/slots/cash-vault-hold-n-link.png",
        overviewUrl: "/slots/cash-vault-hold-n-link/",
        vendor: "NetGame",
        demoUrl:
            "https://games.netgamenv.com/enter.html?game=465&userId=34-21-14-14-93-95&wshost=wss://wss.netgamenv.com&backend=https://api.netgamenv.com&quality=hd&showPromo=true&lang=en",
        isPopular: false,
        isNew: false,
    },
    {
        id: "elven-princesses",
        name: "Elven Princesses",
        logoUrl: "./assets/slots/elven-princesses.png",
        overviewUrl: "/slots/elven-princesses/",
        vendor: "Evoplay",
        demoUrl:
            "//run.evoplay.games/slot/evoplay/elvenprincesses/?operator=4775&session=10121550307&window=1&demo=1&s=174c2fd8c8caffe85fbea4b21becd30e",
        isPopular: false,
        isNew: false,
    },
    {
        id: "rich-piggies-bonus-combo",
        name: "Rich Piggies: Bonus Combo",
        logoUrl: "./assets/slots/rich-piggies-bonus-combo.png",
        overviewUrl: "/slots/rich-piggies-bonus-combo/",
        vendor: "NetGame",
        demoUrl:
            "https://games.netgamenv.com/enter.html?game=559&userId=51-32-77-63-36-05&wshost=wss://wss.netgamenv.com&backend=https://api.netgamenv.com&quality=hd&showPromo=true&lang=en",
        isPopular: true,
        isNew: false,
    },
    {
        id: "lord-of-the-seas",
        name: "Lord Of The Seas",
        logoUrl: "./assets/slots/lord-of-the-seas.png",
        overviewUrl: "/slots/lord-of-the-seas/",
        vendor: "Gamebeat",
        demoUrl:
            "https://games.prod.gamebeat.cloud/lord_of_the_seas/index.html?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiI5NmNhOGQzMy1kYTZlLTQ2NTQtYTA4Ni02ZmM2YTgyNWY1MDAiLCJpYXQiOjE3MDkxNjgyNDMsImV4cCI6MTcwOTI1NDY0M30.DphiY7DBvq_L2nN69sfgjI6KzXbLs6hkAMt624UEe5hXx5pxZE_4Wjuj8v7w8FdjQ4w9AL607f8w9uNRw8YVOdIyCVGLcCvGrj5tVSx0s1IBsHXM4YISPoEknV_VNm9BLEC1r43g_MHPvXAQtaUoEGWvPqeXQB7WgWREKiL3uZ5QNMbQxzGzzsUlGBE5quUJAUvxjZuXtd7WvkKRI_9DkJGtH9mEnsKL2YSttk2qVLNFtOsgF--lYI9jgAF0n7E-jnczgEhGqjTMxz43kfVa0-wc3q_t3NxgM27jQvb2WnwsOz_epjLjpCEUQuBt2afXwONuPm6ywqLBna8qMFqcBA&endpoint=https%3A%2F%2Fgrapes.prod.gamebeat.cloud&historyEndpoint=default&game=lord_of_the_seas&lang=en&returnUrl=https%3A%2F%2Fverdecasino57.com&clientType=desktop",
        isPopular: false,
        isNew: false,
    },
    {
        id: "cactus-riches-cash-pool",
        name: "Cactus Riches: Cash Pool",
        logoUrl: "./assets/slots/cactus-riches-cash-pool.png",
        overviewUrl: "/slots/cactus-riches-cash-pool/",
        vendor: "NetGame",
        demoUrl:
            "https://games.netgamenv.com/enter.html?game=556&userId=68-72-95-17-83-95&wshost=wss://wss.netgamenv.com&backend=https://api.netgamenv.com&quality=hd&showPromo=true&lang=en",
        isPopular: false,
        isNew: false,
    },
    {
        id: "aztec-coins",
        name: "Aztec Coins",
        logoUrl: "./assets/slots/aztec-coins.png",
        overviewUrl: "/slots/aztec-coins/",
        vendor: "Platipus",
        demoUrl:
            "https://prod3.platipusgaming.com/psw/game/demo/GAME_REDIRECT?locale=en_US&token=1b408bb62f3743d78ec1560b4c4480e5&game=azteccoins&lobby=https://web-launch.gingame.net/redirects?redirect_url=ROAQEKpVCnQiHrZZ5iVPacBsqnjHe3KqunQ_r2OwrSaamcYyCYqK5mjYLxxFvrNqJrtnOFmCHDGgLxTkxA_Fz3eNPwJEYUce8p808uabBlL6LPDYcIVXrAdWMhoAuNzW-imDBrusd2Q-jzD5lFPU-Q_7S8HiiCqII0FdqJqRQk_lGWyu6IbV_REu6tuqf654uShBuxcG9hQCqLG91b3fqRrNxu-EhrsC33QK_wVCHReZDfwdKzTPoA%3D%3D&options=",
        isPopular: false,
        isNew: false,
    },
    {
        id: "sizzling-sevens",
        name: "Sizzling Sevens",
        logoUrl: "./assets/slots/sizzling-sevens.svg",
        overviewUrl: "/slots/sizzling-sevens/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/sizzlingsevens/?operator=4775&session=10121550316&window=1&demo=1&s=6650475c0ac33d9c426a1c625561a12d",
        isPopular: true,
        isNew: false,
    },
    {
        id: "verde-diamonds-hunt",
        name: "Verde Diamonds Hunt",
        logoUrl: "./assets/slots/verde-diamonds-hunt.svg",
        overviewUrl: "/slots/verde-diamonds-hunt/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/verdediamondshunt/?operator=4775&session=10121550318&window=1&demo=1&s=0ea5320fe3ab43687098f4660133cf0d",
        isPopular: true,
        isNew: false,
    },
    {
        id: "the-greatest-catch-bonus-buy",
        name: "The Greatest Catch Bonus Buy",
        logoUrl: "./assets/slots/the-greatest-catch-bonus-buy.png",
        overviewUrl: "/slots/the-greatest-catch-bonus-buy/",
        vendor: "Evoplay",
        demoUrl:
            "//run.evoplay.games/slot/evoplay/thegreatestcatchbonusbuy/?operator=4775&session=10121550320&window=1&demo=1&s=fd93abb5da11527f266eea724a429965",
        isPopular: false,
        isNew: false,
    },
    {
        id: "lucky-fruit-spins-bonus-buy",
        name: "Lucky Fruit Spins Bonus Buy",
        logoUrl: "./assets/slots/lucky-fruit-spins-bonus-buy.png",
        overviewUrl: "/slots/lucky-fruit-spins-bonus-buy/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/luckyfruitspinsbb/?operator=4775&session=10121550321&window=1&demo=1&s=7a6b47b5b04ab8d1098ffb71e72194c0",
        isPopular: false,
        isNew: false,
    },
    {
        id: "lucky-fruit-spins",
        name: "Lucky Fruit Spins",
        logoUrl: "./assets/slots/lucky-fruit-spins.png",
        overviewUrl: "/slots/lucky-fruit-spins/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/luckyfruitspins/?operator=4775&session=10121550322&window=1&demo=1&s=88acc4d2a7e05f13537ffb083ff71a36",
        isPopular: false,
        isNew: false,
    },
    {
        id: "fruit-fantasy-40",
        name: "Fruit Fantasy 40",
        logoUrl: "./assets/slots/fruit-fantasy-40.svg",
        overviewUrl: "/slots/fruit-fantasy-40/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/fruitfantasy40/?operator=4775&session=10121550324&window=1&demo=1&s=c4f779bbcd3b65445331590a5e36eda0",
        isPopular: true,
        isNew: false,
    },
    {
        id: "coin-forge-magnetic",
        name: "Coin Forge Magnetic",
        logoUrl: "./assets/slots/coin-forge-magnetic.png",
        overviewUrl: "/slots/coin-forge-magnetic/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/coinforgemagnetic/?operator=4775&session=10121550326&window=1&demo=1&s=6dede4dbd64afada13eb088c042423ba",
        isPopular: false,
        isNew: false,
    },
    {
        id: "fat-panda",
        name: "Fat Panda",
        logoUrl: "./assets/slots/fat-panda.svg",
        overviewUrl: "/slots/fat-panda/",
        vendor: "Pragmatic Play",
        demoUrl:
            "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20beefed&lang=en_US&lobbyUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DaSDgQlicSZa_c5iOBIxL37Vzj_QJe7WqW5A-8soi2WUcyXNMkzTUIrsVZlvU_dRd5DDNfl-6WCk2OE1hI2BGLl5Wz6-6Pt7R-EswMWTOE2-VfAcoqd_zwtuTCyNrWhAIdfH2oRMkdHODwp261uBafPAPflGl9XjsXWD9YM6H7SW2Qp9W3E-NHDhdfyYcUHEo_PMhHazZ5twtxAjStoALLqeTBwtk4QAShUZ3B9hE65MvMHpceYCuMg%253D%253D&stylename=evo_greencasino",
        isPopular: false,
        isNew: false,
    },
    {
        id: "double-up-machine",
        name: "Double Up Machine",
        logoUrl: "./assets/slots/double-up-machine.png",
        overviewUrl: "/slots/double-up-machine/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/doubleupmachine/?operator=4775&session=10121550327&window=1&demo=1&s=f90330494c031ce1cea1ba141566d762",
        isPopular: false,
        isNew: false,
    },
    {
        id: "double-up-respin",
        name: "Double Up Respin",
        logoUrl: "./assets/slots/double-up-respin.svg",
        overviewUrl: "/slots/double-up-respin/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/doubleuprespin/?operator=4775&session=10121550328&window=1&demo=1&s=dccb8a8fcc32447762df24250a1f9ef7",
        isPopular: true,
        isNew: false,
    },
    {
        id: "fairytale-beauties",
        name: "Fairytale Beauties",
        logoUrl: "./assets/slots/fairytale-beauties.svg",
        overviewUrl: "/slots/fairytale-beauties/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=f6a964c12937759425339&gameCode=SlotMachine_FairytaleBeauties&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: true,
        isNew: false,
    },
    {
        id: "2022-hit-slot",
        name: "2022 Hit Slot",
        logoUrl: "./assets/slots/2022-hit-slot.png",
        overviewUrl: "/slots/2022-hit-slot/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/97fc810d94a7c5b002b617318c0fb3bb",
        isPopular: false,
        isNew: false,
    },
    {
        id: "book-of-majestic-king",
        name: "Book Of Majestic King",
        logoUrl: "./assets/slots/book-of-majestic-king.png",
        overviewUrl: "/slots/book-of-majestic-king/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=5dbca5d1293ee03425513&gameCode=SlotMachine_BookOfMajesticKing&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: false,
    },
    {
        id: "verde-win-hold-the-spin",
        name: "Verde Win: Hold The Spin",
        logoUrl: "./assets/slots/verde-win-hold-the-spin.svg",
        overviewUrl: "/slots/verde-win-hold-the-spin/",
        vendor: "Gamzix",
        demoUrl:
            "//cdn-v2.gamzix.com/verde-win-hold-the-spin/v2.0.4/?host=sapp.gamzix.com&port=443&client=desktop&lang=en&home=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DIDF90bfe_vREtCb_LEtT1XcpuTMdCWStDAeLeEsn4_SetZhn9Ouug9ayKD1VkDYxw4p2sKjWRIqg8jfC2xPJ91A%253D&sid=pgdjqyrly&promoVersion=v3.0.0&disablesleepmode=false&slot_core=v0.0.18f&host-promo=prmapp.gamzix.com",
        isPopular: true,
        isNew: false,
    },
    {
        id: "hell-hot-40",
        name: "Hell Hot 40",
        logoUrl: "./assets/slots/hell-hot-40.png",
        overviewUrl: "/slots/hell-hot-40/",
        vendor: "Endorphina",
        demoUrl: "//edemo.endorphina.com/session/open/sid/b9dc920a4a0244c0e970fca1612021df",
        isPopular: false,
        isNew: false,
    },
    {
        id: "demi-gods-v",
        name: "Demi Gods V",
        logoUrl: "./assets/slots/demi-gods-v.svg",
        overviewUrl: "/slots/demi-gods-v/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=a5e26b912931172425569&gameCode=SlotMachine_DemiGodsV&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: true,
        isNew: false,
    },
    {
        id: "queen-of-ice",
        name: "Queen of Ice",
        logoUrl: "./assets/slots/queen-of-ice.png",
        overviewUrl: "/slots/queen-of-ice/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=fab9e1e129327b0424368&gameCode=SlotMachine_QueenOfIce&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: false,
    },
    {
        id: "aztec-spell-forgotten-empire",
        name: "Aztec Spell Forgotten Empire",
        logoUrl: "./assets/slots/aztec-spell-forgotten-empire.png",
        overviewUrl: "/slots/aztec-spell-forgotten-empire/",
        vendor: "Spinomenal",
        demoUrl:
            "//cdn-latam.spinomenal.com/external_components/generic-play.html?partnerId=vulkan-greencasino-new&launchToken=31bc8b712931a57424993&gameCode=SlotMachine_AztecSpell-ForgottenEmpire&langCode=en_US&HomeUrl=postMessage&IsFunMode=true&inter=32&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl",
        isPopular: false,
        isNew: false,
    },
    {
        id: "jokers-invasion",
        name: "Jokers Invasion",
        logoUrl: "./assets/slots/jokers-invasion.png",
        overviewUrl: "/slots/jokers-invasion/",
        vendor: "Slotopia",
        demoUrl:
            "//run.evoplay.games/slot/slotopia/jokersinvasion/?operator=4775&session=10121550343&window=1&demo=1&s=7f6df2a55555326fbf85d2df53917d96",
        isPopular: false,
        isNew: false,
    },
    {
        id: "mega-juicer-5",
        name: "Mega Juicer 5",
        logoUrl: "./assets/slots/mega-juicer-5.png",
        overviewUrl: "/slots/mega-juicer-5/",
        vendor: "Slotopia",
        demoUrl: "//run.evoplay.games/slot/slotopia/megajuicer5/?operator=4775&session=10121550345&window=1&demo=1&s=ddd7043b2fc097d614f30a5870814c1c",
        isPopular: false,
        isNew: false,
    },
    {
        id: "april-fury-and-the-chamber-of-scarabs",
        name: "April Fury and the Chamber of Scarabs",
        logoUrl: "./assets/slots/april-fury-and-the-chamber-of-scarabs.png",
        overviewUrl: "/slots/april-fury-and-the-chamber-of-scarabs/",
        vendor: "Betsoft",
        demoUrl:
            "https://wiraon-c2ss.betsoftgaming.com/cwguestlogin.do?bankId=10235&gameId=910&homeUrl=https%3A%2F%2Fweb-launch.gingame.net%2Fredirects%3Fredirect_url%3DLdfHdOqVUCg3LlyfZuvtR5HQw4LQdzYqVKzzgCSIjGjCGpOfu9GluJHVzzV3D31H1MxKqUhi5SyjUBCVAAK4ao4%253D&lang=en_US",
        isPopular: false,
        isNew: false,
    },
    {
        id: "the-belt-of-champion-bonus-buy",
        name: "The Belt Of Champion Bonus Buy",
        logoUrl: "./assets/slots/the-belt-of-champion-bonus-buy.png",
        overviewUrl: "/slots/the-belt-of-champion-bonus-buy/",
        vendor: "Evoplay",
        demoUrl:
            "//run.evoplay.games/slot/evoplay/thebeltofchampionbb/?operator=4775&session=10121550353&window=1&demo=1&s=b8411fb286379cd780f1ec7210cada46",
        isPopular: false,
        isNew: true,
    },
    {
        id: "christmas-infinite-gifts",
        name: "Christmas Infinite Gifts",
        logoUrl: "./assets/slots/christmas-infinite-gifts.png",
        overviewUrl: "/slots/christmas-infinite-gifts/",
        vendor: "Mascot",
        demoUrl: "https://rc9yb6q5xutywyp.mascot.games/",
        isPopular: false,
        isNew: false,
    },
]
