import { Col, Row } from "react-bootstrap"
import "./Section.css"
import classNames from "classnames"

interface SectionProps {
    heading?: JSX.Element
    children?: React.ReactNode
    className?: string
    padded?: boolean
}

export const Section = ({ children, heading, className, padded = false }: SectionProps) => {
    return (
        <Row>
            <Col className={classNames("sectionContainer", className)}>
                {heading}
                <div className={classNames({ "px-1 px-md-2": padded })}>{children}</div>
            </Col>
        </Row>
    )
}
