import { Info } from "../../components/info/Info"

export const Optibet = () => {
    return (
        <>
            <Info secondary className="pb-5">
                <h2>🌟 Mūsu viedoklis par Optibet</h2>
                <p>
                    Optibet ir populāra azartspēļu platforma Latvijā, kas piedāvā plašu kazino spēļu klāstu, live kazino izpriecas un sporta
                    totalizatoru. Spēlētāji var izvēlēties no spēļu automātiem pazīstamiem izstrādātājiem, kā arī baudīt blekdžeku, ruleti un citas
                    klasiskas galda spēles. Optibet izceļas ar lietotājdraudzīgu mājaslapu latviešu valodā, ērtu mobilo lietotni un profesionālu klientu
                    atbalstu. Papildus, Optibet regulāri piedāvā pievilcīgus bonusus un akcijas, kas palielina spēlētāju izredzes uz laimestu. Tomēr
                    jāņem vērā, ka Optibet nepieejam VIP programma lojālajiem spēlētājiem, un var būt jāuzlabo izmaksu ātrums.
                </p>
            </Info>
        </>
    )
}
