import { Col, Row } from "react-bootstrap"
import "./DataBox.css"

interface DataBoxProps {
    mainText: string
    comment: string
}

export const DataBox = (props: DataBoxProps) => {
    const { mainText, comment } = props

    return (
        <div className="dataBox rounded d-flex flex-column h-100 py-2 justify-content-center">
            <Row>
                <Col className="mainText text-center">{mainText}</Col>
            </Row>
            <Row>
                <Col className="comment text-center">{comment}</Col>
            </Row>
        </div>
    )
}
