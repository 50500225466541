import { Info } from "../../components/info/Info"

export const Laimz = () => {
    return (
        <>
            <Info secondary className="pb-5">
                <h2>🌟 Mūsu viedoklis par Laimz</h2>
                <p>
                    Laimz ir jauns online kazino Latvijā, kas piedāvā plašu spēļu klāstu, ieskaitot spēļu automātus, galda spēles un tiešraides kazino.
                    Lai gan bonusa piedāvājumi vēl tiek attīstīti, kazino izceļas ar ātru reģistrācijas procesu, latviešu valodas atbalstu un modernu
                    mājaslapu. Spēlētājiem, kas meklē jaunu un inovatīvu kazino platformu ar labu spēļu izvēli, Laimz ir apsverēšanas vērts variants.
                    Tomēr, pirms reģistrēšanās, ieteicams iepazīties ar kazino noteikumiem un nosacījumiem, jo informācija par minimālajām iemaksām un
                    izmaksām pašlaik ir ierobežota.
                </p>
            </Info>
        </>
    )
}
