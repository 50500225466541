import { PaymentMethod } from "../../casinoData"
import "./PaymentMethods.css"

interface PaymentMethodProps {
    paymentMethods: PaymentMethod[]
}

export const PaymentMethods = (props: PaymentMethodProps) => {
    const { paymentMethods } = props

    return (
        <div className="paymentMethodsContainer d-flex flex-row flex-wrap gap-2 pb-2">
            {paymentMethods.map((paymentMethod) => {
                return (
                    <div className="paymentMethod rounded" key={paymentMethod}>
                        <img
                            alt={paymentMethod}
                            src={`./assets/payment_methods/${paymentMethod}.svg`}
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            className="p-2"
                        />
                    </div>
                )
            })}
        </div>
    )
}
