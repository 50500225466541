import { Button, Col, Container, Row } from "react-bootstrap"
import { CasinoCard } from "../casinoCard/CasinoCard"
import "./CasinoGrid.css"
import { Casino } from "../../casinoData"
import { useEffect, useState } from "react"

interface CasinoGridProps {
    casinos: Casino[]
    initialShowCount: number
    showLimit?: number
}

export const CasinoGrid = (props: CasinoGridProps) => {
    const { casinos, initialShowCount, showLimit } = props
    const [showCount, setShowCount] = useState(casinos.length)
    const isSliced = showCount < casinos.slice(0, showLimit).length

    useEffect(() => {
        setShowCount(initialShowCount)
    }, [initialShowCount])

    const onClickShowMore = () => {
        setShowCount(showCount * 2)
    }

    return (
        <Container className="casinoGridContainer d-flex flex-column">
            <Row className={`justify-content-center position-relative ${isSliced ? "pb-5" : ""}`}>
                {casinos.slice(0, showCount).map((casino) => {
                    return (
                        <Col className="pb-4" xs={12} sm={6} md={4} key={casino.id}>
                            <CasinoCard casino={casino} />
                        </Col>
                    )
                })}
                {isSliced && (
                    <Row className="showMoreContainer position-absolute bottom-0 d-flex justify-content-center">
                        <Col></Col>
                        <Col xs={5} md={4} lg={3} className="d-flex align-items-end pb-3">
                            <Button variant={"secondary"} className="w-100" onClick={onClickShowMore}>
                                Rādīt vēl
                            </Button>
                        </Col>
                        <Col></Col>
                    </Row>
                )}
            </Row>
        </Container>
    )
}
