import { Button, Carousel } from "react-bootstrap"
import "./PromotionCarousel.css"

export const PromotionCarousel = () => {
    return (
        <Carousel className="promotionCarouselContainer">
            <Carousel.Item className="verde">
                <div className="slide rounded" />
                <Carousel.Caption className="d-flex ms-lg-5 p-5 align-items-end align-items-lg-center justify-content-center justify-content-lg-start">
                    <div className="d-flex justify-content-center align-items-center flex-column w-50 position-absolute">
                        <img className="pb-1" alt="verde" src="./assets/casinos/verde.png" />
                        <div className="caption w-100">
                            <h4 className="p-0 m-0">
                                <span style={{ color: "yellow" }}>150%</span>
                                <span> bonuss līdz </span>
                                <span style={{ color: "yellow" }}>1200€</span>
                            </h4>
                            <h5>
                                <span>+ 220 bezriska griezieni!</span>
                            </h5>
                            <Button variant="success" href={"/go/verde/"}>
                                <strong>Saņemt!</strong>
                            </Button>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="myempire">
                <div className="slide rounded" />
                <Carousel.Caption className="d-flex ms-lg-5 p-5 align-items-end align-items-lg-center justify-content-center justify-content-lg-start">
                    <div className="d-flex justify-content-center align-items-center flex-column w-50 position-absolute">
                        <img className="pb-1" alt="myempire" src="./assets/casinos/myempire.png" />
                        <div className="caption w-100 pb-3">
                            <h4 className="p-0 m-0">
                                <span style={{ color: "yellow" }}>120%</span>
                                <span> bonuss līdz </span>
                                <span style={{ color: "yellow" }}>800€</span>
                            </h4>
                            <h5>
                                <span>+ 300 bezriska griezieni!</span>
                            </h5>
                            <Button variant="success" href={"/go/myempire/"}>
                                <strong>Saņemt!</strong>
                            </Button>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="casombie">
                <div className="slide rounded" />
                <Carousel.Caption className="d-flex p-5 align-items-end align-items-lg-center justify-content-center justify-content-lg-end">
                    <div className="d-flex justify-content-center align-items-center flex-column w-50 position-absolute">
                        <img className="pb-1" alt="casombie" src="./assets/casinos/casombie.png" />
                        <div className="caption w-100">
                            <h4 className="p-0 m-0">
                                <span style={{ color: "yellow" }}>100%</span>
                                <span> bonuss līdz </span>
                                <span style={{ color: "yellow" }}>500€</span>
                            </h4>
                            <h5>
                                <span>+ 200 bezriska griezieni!</span>
                            </h5>
                            <Button variant="success" href={"/go/casombie/"}>
                                <strong>Saņemt!</strong>
                            </Button>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}
