import { useLoaderData } from "react-router-dom"
import { Casino, gameNameMap, games, getRandomPartneredCasinos } from "../../casinoData"
import { Casombie } from "./Casombie"
import { GGBet } from "./GGBet"
import { CasinoBanner } from "../../components/casinoBanner/CasinoBanner"
import { Badge, Col, Container, ListGroup, Row } from "react-bootstrap"
import "./Overview.css"
import { PaymentMethods } from "../../components/paymentMethods/PaymentMethods"
import { CasinoGrid } from "../../components/casinoGrid/CasinoGrid"
import { Helmet } from "react-helmet"
import seedrandom from "seedrandom"
import { Verde } from "./Verde"
import { Drip } from "./Drip"
import { Betinia } from "./Betinia"
import { FiveGringos } from "./5Gringos"
import { Boomerang } from "./Boomerang"
import { Sportaza } from "./Sportaza"
import { Posido } from "./Posido"
import { Frumzi } from "./Frumzi"
import { SevenSigns } from "./7Signs"
import { AmunRa } from "./AmunRa"
import { Neon54 } from "./Neon54"
import { MyEmpire } from "./MyEmpire"
import { SlotsPalace } from "./SlotsPalace"
import { Currenciess } from "../../components/currencies/Currencies"
import { StickyCasinoBanner } from "../../components/stickyCasinoBanner/StickyCasinoBanner"
import { CosmicSlot } from "./CosmicSlot"
import { SpinsBro } from "./SpinsBro"
import { Ice } from "./Ice"
import { LVBET } from "./LVBet"
import { Optibet } from "./Optibet"
import { Laimz } from "./Laimz"
import { Klondaika } from "./Klondaika"

export const Overview = () => {
    const casino = useLoaderData() as Casino

    const getCasinoReview = (id: string) => {
        switch (id) {
            case "casombie":
                return <Casombie />
            case "ggbet":
                return <GGBet />
            case "verde":
                return <Verde />
            case "drip":
                return <Drip />
            case "betinia":
                return <Betinia />
            case "5gringos":
                return <FiveGringos />
            case "boomerang":
                return <Boomerang />
            case "sportaza":
                return <Sportaza />
            case "posido":
                return <Posido />
            case "frumzi":
                return <Frumzi />
            case "7signs":
                return <SevenSigns />
            case "amunra":
                return <AmunRa />
            case "neon54":
                return <Neon54 />
            case "myempire":
                return <MyEmpire />
            case "slotspalace":
                return <SlotsPalace />
            case "cosmicslot":
                return <CosmicSlot />
            case "spinsbro":
                return <SpinsBro />
            case "ice":
                return <Ice />
            case "lvbet":
                return <LVBET />
            case "optibet":
                return <Optibet />
            case "laimz":
                return <Laimz />
            case "klondaika":
                return <Klondaika />
            default:
                return null
        }
    }

    const getCasinoTitle = () => {
        const rng = seedrandom(casino.id)
        const bonusTitlesVariants = ["Reģistrācijas Bonuss", "Bonuss Līdz", "Balvā", "Saņem Līdz", "Dāvanā"]
        const plusTitlesVaritants = ["+", "un"]
        const freeSpinsTitleVariants = ["Griezieni", "Brīvgriezieni", "Bezriska Griezieni", "FS"]

        const randomPlus = plusTitlesVaritants[Math.floor(rng.quick() * plusTitlesVaritants.length)]
        const randomBonus = bonusTitlesVariants[Math.floor(rng.quick() * bonusTitlesVariants.length)]
        const randomFreeSpins = freeSpinsTitleVariants[Math.floor(rng.quick() * freeSpinsTitleVariants.length)]

        const bonusTitle = casino.bonus
            ? casino.bonus === Infinity
                ? `Bezlimita Bonuss ${randomPlus}`
                : ` ${randomBonus} ${casino.bonus}€ ${randomPlus} `
            : ""
        const freeSpinsTitle = `${casino.freeSpins} ${randomFreeSpins}`

        const title = `${casino.name} Kazino 🎁${bonusTitle} ${freeSpinsTitle}`
        return title
    }

    const getCasinoDescription = () => {
        const rng = seedrandom(casino.id)
        const descriptionVariants = [
            `Iepazīsties ar ${casino.name} ārzemju kazino: 2024. gada jaunākie bonusi un spēļu iespējas Latvijas azartspēļu mīļotājiem!`,
            `${casino.name} kazino 2024. gadā: labākie piedāvājumi un spēļu klāsts latviešu spēlētājiem - izvēlies drošu un izklaidējošu azartu tiešsaistē!`,
            `Latviešu kazino piedzīvojumi ar ${casino.name} 2024. gadā - jaunākie bonusi, aizraujoši spēļu automāti un garantēta drošība!`,
            `2024. gadā atklāj ${casino.name} online kazino Latvijā - vislabākie ārzemju kazino piedāvājumi latviešu spēlētājiem, kas garantē drošu un aizraujošu spēļu pieredzi!`,
            `${casino.name} 2024. gadā: online kazino ar lieliskiem bonusiem un bezriska griezieniem - izmēģini spēļu daudzveidību un izmanto bonusu iespējas Latvijas tirgū!`,
            `Atklāj ${casino.name} online kazino 2024. gadā - vislabākais ārzemju kazino Latvijas tirgū ar plašu bonuss piedāvājumu klāstu un izdevīgiem bezriska griezieniem!`,
        ]

        const description = descriptionVariants[Math.floor(rng.quick() * descriptionVariants.length)]

        return description
    }

    return (
        <>
            <Helmet>
                <title>{getCasinoTitle()}</title>
                <meta name="description" content={`${getCasinoDescription()}`} />
            </Helmet>
            <Container className="overviewContainer">
                <h1 className="pt-2">{`${casino.name} kazino`}</h1>
                <Row>
                    <Col>
                        <CasinoBanner casino={casino} theme="light" showOverview={false} />
                    </Col>
                </Row>
                <Row className="py-2 gap-2">
                    <Col className="infoSection rounded" lg={4}>
                        <h5 className="pt-3">Maksājumi</h5>
                        <hr />
                        <ListGroup variant="flush" className="pb-2">
                            <ListGroup.Item className="rounded d-flex">
                                <img alt="iemaksa" src="./assets/icons/deposit.png" width="24px" className="align-middle" />
                                <span className="ps-2">Minimālā iemaksa:</span>
                                <Badge bg="secondary" className="ms-auto d-flex align-items-center">
                                    {casino.minDeposit}
                                </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded d-flex">
                                <img alt="izmaksa" src="./assets/icons/withdraw.png" width="24px" className="align-middle" />
                                <span className="ps-2">Minimālā izmaksa:</span>
                                <Badge bg="secondary" className="ms-auto d-flex align-items-center">
                                    {casino.minWithdraw}
                                </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded">
                                <Row>
                                    <Col className="pe-0" xs={"auto"}>
                                        <img alt="valūta" src="./assets/icons/currency.png" width="24px" className="align-middle" />
                                        <span className="ps-2">Valūtas:</span>
                                    </Col>
                                    <Col className="d-flex justify-content-end flex-wrap gap-1 align-items-center">
                                        <Currenciess currencies={casino.currencies} showLimit={3} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <hr />
                        <PaymentMethods paymentMethods={casino.paymentMethods} />
                    </Col>
                    <Col className="infoSection rounded" lg={4} md={6}>
                        <h5 className="pt-3">Spēles</h5>
                        <hr />
                        <ListGroup variant="flush" className="pb-2">
                            {games.map((game) => {
                                const hasGame = !casino.missingGames.includes(game)

                                return (
                                    <ListGroup.Item className="rounded d-flex align-items-center" key={game}>
                                        <img
                                            alt={hasGame ? "Ir" : "Nav"}
                                            src={`./assets/icons/games/${game}.png`}
                                            width="24px"
                                            className="align-middle"
                                        />
                                        <span className="ps-2">{gameNameMap[game]}</span>
                                        <Badge bg={hasGame ? "primary" : "secondary"} className="ms-auto h-100" style={{ width: "3em" }}>
                                            {hasGame ? "Ir" : "Nav"}
                                        </Badge>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                    <Col className="infoSection rounded" xs={undefined}>
                        <h5 className="pt-3">Plusi</h5>
                        <hr />
                        <ListGroup variant="flush">
                            {casino.pros.map((pro, proIndex) => {
                                return (
                                    <ListGroup.Item className="rounded d-flex align-items-center" key={proIndex}>
                                        <img alt="pluss" src="./assets/icons/check.png" width="18px" className="align-middle" />
                                        <span className="ps-2">{pro}</span>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        <h5 className="pt-3">Mīnusi</h5>
                        <hr />
                        <ListGroup variant="flush" className="pb-2">
                            {casino.cons.map((con, conIndex) => {
                                return (
                                    <ListGroup.Item className="rounded d-flex align-items-center" key={conIndex}>
                                        <img alt="minuss" src="./assets/icons/cross.png" width="18px" className="align-middle" />
                                        <span className="ps-2">{con}</span>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
            <div className="reviewContainer">{getCasinoReview(casino.id)}</div>
            <Container className="py-5">
                <h2>{`Ja tev patīk ${casino.name}, tev patiks arī šie:`}</h2>
                <CasinoGrid casinos={getRandomPartneredCasinos(casino.id).filter((c) => c.id !== casino.id)} initialShowCount={3} showLimit={3} />
            </Container>
            <StickyCasinoBanner casino={casino} />
        </>
    )
}
