import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap"
import "./Navigation.css"
import { Logo } from "../logo/Logo"
import { useState } from "react"

export const Navigation = () => {
    const [expanded, setExpanded] = useState(false)

    return (
        <Navbar className="navbarContainer p-2" expanded={expanded} expand="lg" fixed="top">
            <Container fluid>
                <Navbar.Brand href="/" onClick={() => setExpanded(false)} itemProp="url">
                    <Logo />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-navbar" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="main-navbar">
                    <Nav className="m-auto gap-2 h-100">
                        <NavDropdown
                            renderMenuOnMount
                            title={
                                <>
                                    <img
                                        src="./assets/icons/spades.png"
                                        alt="kazino"
                                        className="d-block-inline align-middle me-2"
                                        style={{ maxHeight: "16px" }}
                                    />
                                    Kazino
                                </>
                            }
                        >
                            <NavDropdown.Item href="/arzemju-kazino/" onClick={() => setExpanded(false)} itemProp="url">
                                Ārzemju Kazino
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/latvijas-kazino/" onClick={() => setExpanded(false)} itemProp="url">
                                Latvijas Kazino
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/sloti/" onClick={() => setExpanded(false)} itemProp="url">
                            <img src="./assets/icons/seven.png" alt="sloti" className="align-middle me-2" style={{ maxHeight: "16px" }} />
                            Sloti
                        </Nav.Link>
                        <Nav.Link href="/bonusi/" onClick={() => setExpanded(false)} itemProp="url">
                            <img src="./assets/icons/bonus.png" alt="bonusi" className="align-middle me-2" style={{ maxHeight: "20px" }} />
                            Bonusi
                        </Nav.Link>
                        <Nav.Link href="/bezriska-griezieni/" onClick={() => setExpanded(false)} itemProp="url">
                            <img src="./assets/icons/spin.png" alt="bezriska griezieni" className="align-middle me-2" style={{ maxHeight: "20px" }} />
                            Bezriska griezieni
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Brand href="/" className="invisible d-none d-lg-block">
                    <Logo />
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}
