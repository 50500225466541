import { Col, Container, Row } from "react-bootstrap"
import "./Footer.css"
import { Logo } from "../logo/Logo"

export const Footer = () => {
    return (
        <Container fluid className="footerContainer">
            <Container className="">
                <Row>
                    <Col className="d-flex flex-column pt-5 pb-3">
                        <Row>
                            <Col className="d-flex flex-wrap justify-content-center gap-5 pb-4">
                                <a href="/">Sākums</a>
                                <a href="/latvijas-kazino/">Latvijas kazino</a>
                                <a href="/arzemju-kazino/">Ārzemju kazino</a>
                                <a href="/sloti/">Sloti</a>
                                <a href="/bonusi/">Bonusi</a>
                                <a href="/bezriska-griezieni/">Bezriska griezieni</a>
                            </Col>
                        </Row>
                        <hr className="opacity-25" />
                        <Row>
                            <Col xs={12} sm={6} md={4} className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                <Logo />
                            </Col>
                            <Col xs={12} sm={6} md={4} className="d-flex justify-content-center align-items-center gap-3 pt-3 pt-sm-0">
                                <small className="py-2">info@kazinounkazino.com</small>
                                <a className="social" href="https://twitter.com/KazinoUnKazino">
                                    <img alt="18 pluss" src="./assets/social/x.png" height="24" />
                                </a>
                                <a className="social" href="https://www.tiktok.com/@kazinounkazino">
                                    <img alt="18 pluss" src="./assets/social/tiktok.png" height="24" />
                                </a>
                            </Col>
                            <Col xs={12} md={4} className="d-flex justify-content-center justify-content-md-end align-items-center pt-3 pt-md-0">
                                <small>2024 © kazinounkazino.com</small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
