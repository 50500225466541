import { Navigation } from "./components/navigation/Navigation"
import { Footer } from "./components/footer/Footer"
import { createBrowserRouter, createRoutesFromElements, Outlet, redirect, Route, RouterProvider, useLocation } from "react-router-dom"
import { Home } from "./routes/home/Home"
import { Error } from "./routes/error/Error"
import { Bonuses } from "./routes/bonuses/Bonuses"
import { LatvianCasinos } from "./routes/latvianCasinos/LatvianCasinos"
import { AbroadCasinos } from "./routes/abroadCasinos/AbroadCasinos"
import { Overview } from "./routes/casinos/Overview"
import { useState } from "react"
import { Slots } from "./routes/slots/Slots"
import { SlotView } from "./routes/slot/Slot"
import { getCasinos } from "./casinoData"
import { getSlots } from "./slotData"
import { FreeSpins } from "./routes/freeSpins/FreeSpins"
import "./App.css"
import { Helmet } from "react-helmet"

const Layout = () => {
    const location = useLocation()
    const [title, setTitle] = useState<undefined | string>(undefined)
    const [description, setDescription] = useState<undefined | string>(undefined)

    const onChangeState = (newState: any) => {
        setTitle(newState.title.toString())
        setDescription(newState.metaTags.find((tag: any) => tag.name === "description")?.content || "")
    }

    return (
        <div className="app pt-5 mt-2">
            <Navigation />
            <Outlet />
            <Footer />
            <Helmet onChangeClientState={onChangeState} />
            <Helmet>
                <link rel="canonical" href={`https://www.kazinounkazino.com${location.pathname}`} />

                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.kazinounkazino.com/assets/kuk_logo_black.jpg" />
                <meta property="og:url" content={`https://www.kazinounkazino.com${location.pathname}`} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />

                <meta name="twitter:image" content="https://www.kazinounkazino.com/assets/kuk_logo_black.jpg" />
                <meta name="twitter:url" content={`https://www.kazinounkazino.com${location.pathname}`} />
                <meta name="twitter:site" content="Kazino un Kazino" />
                <meta name="twitter:card" content="website" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
        </div>
    )
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Home />} />
            <Route path="/latvijas-kazino" element={<LatvianCasinos />} />
            <Route path="/arzemju-kazino" element={<AbroadCasinos />} />
            <Route path="/bonusi" element={<Bonuses />} />
            <Route path="/sloti" element={<Slots />} />
            <Route path="/bezriska-griezieni" element={<FreeSpins />} />
            <Route
                path="/kazino/:casinoId"
                element={<Overview />}
                loader={({ params }) => {
                    const casino = getCasinos().find((casino) => casino.id === params.casinoId && casino.overviewUrl)
                    if (!casino) {
                        return redirect("/404/")
                    }

                    return casino
                }}
            />
            <Route
                path="/slots/:slotId"
                element={<SlotView />}
                loader={({ params }) => {
                    const slot = getSlots().find((slot) => slot.id === params.slotId && slot.overviewUrl)
                    if (!slot) {
                        return redirect("/404/")
                    }

                    return slot
                }}
            />
        </Route>,
    ),
)

export const App = () => {
    return <RouterProvider router={router} />
}
