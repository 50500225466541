import { Container } from "react-bootstrap"
import "./Info.css"
import classNames from "classnames"

interface InfoProps {
    children: JSX.Element[] | JSX.Element
    secondary?: boolean
    className?: string
}

export const Info = (props: InfoProps) => {
    const { children, secondary, className } = props

    return (
        <Container fluid className={classNames("infoContainer", className, { secondary: secondary })}>
            <Container>{children}</Container>
        </Container>
    )
}
