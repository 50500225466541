import { Button, Card, Col, Row } from "react-bootstrap"
import { Casino } from "../../casinoData"
import { DataBox } from "../dataBox/DataBox"
import "./CasinoCard.css"
import ReactGA from "react-ga4"

interface CasinoCardProps {
    casino: Casino
}

export const CasinoCard = (props: CasinoCardProps) => {
    const { casino } = props

    const onClickBonus = () => {
        ReactGA.event({
            category: "Promotion",
            action: "clicked_bonus",
            label: casino.id,
            value: 1,
        })
    }

    return (
        <Card className="cardContainer" itemScope itemType="https://schema.org/Casino">
            <a
                className="imageContainer d-flex align-items-center rounded-top"
                href={casino.overviewUrl}
                style={{ backgroundColor: casino.backgroundColor }}
            >
                <Card.Img alt={casino.name} className="mx-auto" variant="top" src={casino.logoUrl} itemProp="image" />
            </a>
            <Card.Body>
                <Row>
                    {casino.bonus && (
                        <Col itemProp="name">
                            <DataBox mainText={casino.bonus === Infinity ? "Bezlimita" : casino.bonus.toString() + "€"} comment="Bonuss" />
                        </Col>
                    )}
                    {casino.freeSpins && (
                        <Col>
                            <DataBox mainText={casino.freeSpins.toString()} comment="Bezriska griezieni" />
                        </Col>
                    )}
                </Row>
                <Row className="text-center">
                    <Col>
                        <Button
                            variant={casino.isPartner ? "success" : "secondary"}
                            className="w-100 mt-3"
                            href={`/go/${casino.id}/`}
                            onClick={onClickBonus}
                            rel="nofollow"
                            itemProp="address"
                        >
                            <strong>{casino.isPartner ? "Saņemt bonusu" : "Apmeklēt kazino"}</strong>
                        </Button>
                    </Col>
                    {casino.overviewUrl !== undefined && (
                        <a className="overviewLink pt-3" href={casino.overviewUrl} itemProp="url">
                            <img alt="info" className="align-middle mx-2" src="./assets/icons/info.svg" />
                            <span>{`Vairāk par ${casino.name}`}</span>
                        </a>
                    )}
                </Row>
            </Card.Body>
        </Card>
    )
}
