import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { App } from "./App"
import { createRoot, hydrateRoot } from "react-dom/client"
import ReactGA from "react-ga4"

const rootElement = document.getElementById("root")
if (rootElement) {
    ReactGA.initialize("G-08FRSLWBRT")

    if (rootElement.hasChildNodes()) {
        hydrateRoot(rootElement, <App />)
    } else {
        const root = createRoot(rootElement)
        root.render(<App />)
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
