import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Currency } from "../../casinoData"
import "./Currencies.css"

interface CurrenciesProps {
    currencies: Currency[]
    showLimit?: number
}

export const Currenciess = (props: CurrenciesProps) => {
    const { currencies, showLimit } = props

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            <div className="d-flex justify-content-center flex-wrap gap-1">
                {currencies.slice(showLimit, currencies.length).map((currency) => {
                    return (
                        <Badge bg="secondary" key={currency}>
                            {currency}
                        </Badge>
                    )
                })}
            </div>
        </Tooltip>
    )

    return (
        <>
            {currencies.slice(0, showLimit).map((currency) => {
                return (
                    <Badge bg="secondary" key={currency}>
                        {currency}
                    </Badge>
                )
            })}
            {currencies.length > (showLimit || Infinity) && (
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 250 }} overlay={renderTooltip}>
                    <Badge bg="primary">+</Badge>
                </OverlayTrigger>
            )}
        </>
    )
}
