import { Helmet } from "react-helmet"
import { getPartneredCasinos } from "../../casinoData"
import { CasinoBanner } from "../../components/casinoBanner/CasinoBanner"
import { Info } from "../../components/info/Info"
import { Accordion, Container } from "react-bootstrap"

export const Bonuses = () => {
    const bonusCasinos = getPartneredCasinos().filter((casino) => (casino.bonus || 0) > 500)

    return (
        <>
            <Helmet>
                <title>Labākie Kazino Bonusi Latvijā 2024 🎖️ Bez Pirmās Iemaksas un Depozīta!</title>
                <meta
                    name="description"
                    content="Labākie kazino bonusi Latvijā 2024. Iegūsti labus piedāvājumus, bezmaksas griezienus un bonusus top online kazino. Ceļvedis iesācējiem un rūdītiem spēlētājiem."
                />
            </Helmet>
            <Container>
                <h1>Kazino bonusi, kas tie ir?</h1>
                <p>
                    Kazino bonusi ir būtiska tiešsaistes azartspēļu pasaules daļa, kas spēlētājiem piedāvā papildu priekšrocības. Tie var būt
                    iepazīšanās bonusi, bezmaksas griezieni, cashback, jeb naudas atgriešanas bonuss un lojalitātes programmas. Iepazīšanās bonusi ir
                    procentuāls atbilstības bonusu no pirmā depozīta summas. Bezmaksas griezieni ļauj spēlētājiem bez maksas griezt noteiktus spēļu
                    automātus. Cashback ir procentuāla summa, ko kazino atdod spēlētājam no zaudētās summas noteiktā laika periodā. Lojalitātes
                    programmas ir veids, kā kazino atzīmē un apbalvo savus ilgtermiņa spēlētājus. Tomēr ir svarīgi rūpīgi izlasīt bonusa noteikumus un
                    nosacījumus, lai izvairītos no nepatīkamām pārsteigumiem.
                </p>
                <p>Ieskatīsimies detalizētāk kā strādā kats no šime bonusiem, un kā mēs varam vislabāk viņus pielietot.</p>
            </Container>
            <Info>
                <>
                    {bonusCasinos.map((casino) => {
                        return <CasinoBanner casino={casino} key={casino.id} />
                    })}
                </>
                <br />
                <h2>Online kazino bonusu veidi</h2>
                <p>
                    Kazino bonusi ar katru dienu kļūst daudzveidīgāki, jo mūsdienu cilvēks ir aizvien prasīgāks un ar vienu piedāvājumu neizdosies
                    iespaidot visus. Reti atradīsies bonusi, kas spēs nošaut vairākus zaķus ar vienu šāvienu. Ne visi online kazino apmeklētāji vēlas
                    veikt depozīta iemaksas, lai saņemtu īslaicīgu prieku ar trakām apgrozījuma prasībām. Spēlētāji kļūst arvien gudrāki un spēj izsvērt
                    patiesu labumu, tāpēc operatoriem nākas pasvīst bonusu piedāvājuma izveidē. Jāpiebilst, ka Latvijas online kazino bonusi nebūt
                    neatpaliek no ārzemju vitnēs sastopamajiem bonusiem.
                </p>
                <p>
                    Vistīkamākie spēlētājiem bieži ir tie bonusi, kuriem ir bezriska griezieni vai bezmaksas griezieni, jo tas nozīmē vairāk iespēju
                    iepazīt kazino spēles un iegriezt žilbinošus laimestus. Bezmaksas griezienu bonuss būs aktuāls ārzemju vietnēs, bet bezriska
                    griezieni rotā Latvijas online kazino piedāvājumu sadaļu. Iecienīts ir arī cashback jeb naudas atmaksas bonuss, kas mīkstina
                    zaudējumu smagumu. Ja abi iet roku rokā, tad konkrēto bonusu bez šaubām var nosaukt par dāsnu.
                </p>
                <p>
                    Jāliek aiz auss, ka ir reālā nauda, ko spēlētājs iemaksā online kazino spēles kontā, bet ir arī bonusa nauda, kuru gūst no bonusiem.
                    Šis iedalījums ir izteikti aktuāls tieši ārzemju platformās. Visbiežāk jau mazajā reklāmlogā būs izcelts bonusa apjoms, kuru var
                    atpazīt pēc procentuālā apjoma un summas naudas izteiksmes, piemēram, 100% bonuss līdz pat 100€. Tas nozīmē, ka iemaksājot 10€,
                    spēlētājs saņem papildus 10€ kā bonusa naudu. Jāsaprot, ka minētajā piemērā, lai arī iemaksāti tiks 150€, spēlētājs tāpat saņems
                    tikai maksimālos 100€.
                </p>
                <h2>Jauno klientu iepazīšanās bonuss</h2>
                <p>
                    Visa sākums ir iepazīšanās bonuss. Lielākā daļa online kazino operatoru tādu nodrošina, lai piesaistītu jaunus spēlētājus. Vairumā
                    gadījumu tieši iepazīšanās bonusi ir tie, kas nopelna vislielāko uzmanību. Spēlētāji vēlas saņemt pamudinājumu jau pirmo soļu laikā,
                    lai izprastu spēles laukumu, izmēģinātu pēc iespējas vairāk no spēļu automātu piedāvājuma un nezaudētu lielus naudas līdzekļus.
                </p>
                <p className="text-center">
                    <img alt="spins" src="./assets/spins_promo.png" width={"100%"} style={{ maxHeight: "300px", objectFit: "contain" }} />
                </p>
                <p>
                    Ar iepazīšanās bonusiem spēlētājs visbiežāk tiek pie tādiem labumiem kā bezmaksas griezieni, bonusa nauda, naudas atmaksa, bezriska
                    griezieni vai visa iepriekš uzskaitītā kombinācija. Iepazīšanās bonuss lielākajā daļā online vietņu uztverams kā pirmās iemaksas jeb
                    depozīta bonuss. Tomēr ir daži tiešsaistes kazino, kuri iet uz pilnu banku un depozīta bonuss tiek paplašināts, sadalot to daļās
                    līdz pirmajām piecām iemaksām. Pirmās iemaksas bonusa iegūšanai, kas jau top skaidrs pēc nosaukuma, nepieciešams veikt depozītu.
                </p>
                <p>
                    Bonusi šajā kategorijā variē, jo ir manāmi gan bonusi bez depozīta, gan tādi, kuru saņemšanai jāiemaksā konkrēta naudas summa.
                    Pirmās iemaksas bonuss nereti var izpausties kā bezmaksas griezieni vai bonuss līdz noteiktai summai. Visbiežāk tieši pirmās
                    iemaksas bonuss nodrošina iepazīšanos ar spēļu automātu pasauli.
                </p>
                <h2>Bezriska griezieni</h2>
                <p>
                    Latvijā licencētie online kazino atšķiras ar to, ka tajos spēlētāju sagaidīs nevis bezmaksas griezienu bonuss, bet gan bezriska
                    griezieni. Un, nē, tie nav tie paši bezmaksas griezieni. Pirmajā mirklī gan atšķirību ir grūti saprast, jo arī šie bezriska
                    griezieni nosacīti ļauj iegriezt spēļu automātus par brīvu. Tomēr tikai bezmaksas griezieni nodrošina laimestu bez riskēšanas ar
                    saviem līdzekļiem.
                </p>
                <p>
                    Lai arī nosaukums šķietami liecina, ka grieziens neprasīs riskantus momentus, bezriska griezieni paredz, ka no sākuma spēlētājam
                    likme jāveic ieguldot savu naudu un, ja grieziens bijis neveiksmīgs, spēlētāja kontā pieejama naudas atmaksa. Papildus tam nereti
                    norādītas konkrētas spēļu automātu spēles, kurās šie bezriska griezieni ir derīgi.
                </p>
                <p>
                    Tātad, spēlētājs liek likmi par savu naudu konkrētā spēles, bet pēcāk viņam pienākas naudas atmaksa, kuras apmērs var būt tikpat
                    liels, cik likmes vai zaudēto griezienu vērtība. Piemēram, 100 bezriska griezieni, kuru likmes vērtība ir 0,10€ = 10€ naudas
                    atmaksa.
                </p>
                <h2>Iepazīšanās bonuss bez depozīta</h2>
                <p>
                    Kas gan var skanēt labāk, kā iegūt kazino bonusus bez depozīta veikšanas? Bet jāsaprot, ka nekas, nekad un nekur nav par velti.
                    Bonusi bez depozīta, ir online vietņu piedāvātie stimuli jaunu spēlētāju piesaistīšanai. Bonusi bez depozīta ļauj spēlēt noteiktas
                    kazino spēles bez nepieciešamības veikt savas naudas iemaksu. Lai gan šāds piedāvājums nav tik izplatīts kā depozīta bonuss, to
                    joprojām var atrast dažās vietnēs.
                </p>
                <p>
                    Bonusi bez depozīta ir visai reta parādība, un atrast kazino, kurš to spēlētājiem sniedz, nav viegli. Turkāt, ja pat paveicās,
                    bonuss bez depozīta nāk roku rokā ar zemu vērtību. Respektīvi, par spīti nosaukumam, šie bonusi nav tik vērtīgi kā parastais
                    iepazīšanās bonuss, jo griezienu skaits nav liels. Turklāt, bieži vien iepazīšanās bonuss bez depozīta nāk ar nepatīkami lielām
                    apgrozījuma prasībām, kā arī citiem šķēršļiem laimestus izņemt.
                </p>
                <p>
                    Jāņem vērā, ka uz bez depozīta bonusiem bieži attiecas stingrāki noteikumi un nosacījumi, salīdzinot ar bonusiem, kas balstīti uz
                    depozītu. Ir svarīgi izprast ar piedāvājumu saistītos noteikumus un prasības, lai izvairītos no jebkādiem pārpratumiem vai vilšanās.
                </p>
                <h2>Cashback jeb naudas atmaksa</h2>
                <p>
                    Latvijas kazino spēlētājiem cieņā ir cashback jeb naudas atmaksa, popularitātē līdztekus stāv vien bezriska griezieni. Naudas
                    atmaksa paredz, ka spēlētājs atgūst noteiktu procentuālo daļu no zaudējumiem. Procentu lielums atšķiras katrā online kazino, bet
                    bieži tas ir 10-15% robežās.
                </p>
                <p>
                    Naudas atmaksa var tikt piedāvāta, kad tiek iemaksāts pirmais depozīts. Citiem vārdiem, tas itin bieži var būt kā daļa vai pat viss
                    iepazīšanās bonuss. Kā jau iepriekš tika minēts, tas jauno klientu uzrunā, jo iespēja zaudēt lielu naudas summu jau ceļa sākumā
                    mazinās.
                </p>

                <h2>Biežāk uzdotie jautājumi par kazino bonusiem</h2>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header as="h3">Kā darbojas kazino bonusi?</Accordion.Header>
                        <Accordion.Body>
                            Kazino bonusi darbojas kā veids, kā piesaistīt jaunus spēlētājus un uzturēt esošos. Jaunajiem spēlētājiem parasti tiek
                            piedāvāts sagaidīšanas vai reģistrācijas bonuss, kas var būt bezmaksas griezieni vai iemaksas atbilstības bonuss. Esošajiem
                            spēlētājiem kazino var piedāvāt regulāras akcijas, lojalitātes programmas vai speciālus piedāvājumus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header as="h3">Kā izmantot kazino bonusus?</Accordion.Header>
                        <Accordion.Body>
                            Lai izmantotu kazino bonusus, spēlētājam bieži vien ir jāveic noteiktas darbības vai jāievada bonusa kods. Piemēram, lai
                            saņemtu reģistrācijas bonuss, spēlētājam var būt jāreģistrējas kazino un jāveic minimālā iemaksa. Pēc tam kazino automātiski
                            piešķir bonusu vai spēlētājam ir jāpieprasa bonuss, izmantojot bonusa kodu.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header as="h3">Kā izvēlēties labākos kazino bonusus?</Accordion.Header>
                        <Accordion.Body>
                            Labākos kazino bonusus izvēlēties, ir svarīgi izpētīt bonusa nosacījumus un prasības. Ņem vērā likmju prasības, derīguma
                            termiņus, minimālo iemaksas summu un citus noteikumus, lai saprastu, vai piedāvājums ir izdevīgs. Turklāt, salīdzini dažādu
                            kazino bonusus, lai atrastu labāko piedāvājumu atbilstoši savām spēlēšanas vajadzībām.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Info>
        </>
    )
}
