import "./Logo.css"

export const Logo = () => {
    return (
        <div className="logoContainer">
            <img alt="logo" src="./assets/icons/spades.png" width="26" height="26" className="d-inline-block align-middle me-2" />
            <span>KAZINO</span>
            <span style={{ color: "#cd1636" }}>UN</span>
            <span>KAZINO</span>
        </div>
    )
}
