import seedrandom from "seedrandom"

const paymentMethods = [
    "visa",
    "mastercard",
    "revolut",
    "skrill",
    "neteller",
    "piastrix",
    "bitcoin",
    "litecoin",
    "ethereum",
    "jeton",
    "muchbetter",
    "trustly",
    "ecoPayz",
    "flexepin",
    "neosurf",
    "giropay",
    "paysafe",
    "tether",
    "rapidTransfer",
    "binance",
    "litecoin",
    "mifinity",
    "ripple",
    "astropay",
    "interac",
    "interacE",
    "klarna",
    "postePay",
    "bankTransfer",
    "ezeeWallet",
] as const

export const currencies = [
    "EUR",
    "USD",
    "RUB",
    "BTC",
    "ETH",
    "LTC",
    "CAD",
    "HUF",
    "NOK",
    "PLN",
    "INR",
    "NZD",
    "BRL",
    "CZK",
    "CLP",
    "PEN",
    "CHF",
    "TRY",
    "THB",
    "NGN",
    "ZAR",
    "MXN",
    "SGD",
    "TND",
    "MYR",
    "PHP",
    "KRW",
    "KZT",
    "JPY",
    "AUD",
    "COP",
    "DKK",
    "IDR",
    "VND",
    "HKD",
    "AZN",
] as const

export const games = [
    "slots",
    "liveCasino",
    "sportsBetting",
    "roulette",
    "blackjack",
    "baccarat",
    "virtualSports",
    "poker",
    "bingo",
    "gameShow",
] as const

export const gameNameMap: Record<Games, string> = {
    slots: "Sloti",
    liveCasino: "Dzīvais kazino",
    sportsBetting: "Sporta totalizators",
    roulette: "Rulete",
    blackjack: "Blekdžeks",
    baccarat: "Bakara",
    virtualSports: "Virtuālais sports",
    poker: "Pokers",
    bingo: "Bingo",
    gameShow: "Spēļu šovi",
}

export type PaymentMethod = (typeof paymentMethods)[number]
export type Currency = (typeof currencies)[number]
export type Games = (typeof games)[number]

export interface Casino {
    id: string
    name: string
    referralUrl: string
    overviewUrl?: string
    logoUrl: string
    backgroundColor: string
    paymentMethods: PaymentMethod[]
    minDeposit: number
    minWithdraw: number
    currencies: Currency[]
    missingGames: Games[]
    pros: string[]
    cons: string[]
    bonus?: number
    bonusExtended?: string
    freeSpins?: number
    rating: number
    gamesCount: number
    founded: number
    isLatvian?: boolean
    isPartner?: boolean
}

export const getCasinos = () => {
    return [...casinos]
}

export const getCasinoById = (id: string) => {
    return getCasinos().find((casino) => casino.id === id)
}

export const getRandomPartneredCasinos = (seed: string) => {
    const rng = seedrandom(seed)
    const partneredCasinos = [...casinos].filter((casino) => casino.isPartner)
    return [...partneredCasinos].sort(() => 0.5 - rng.quick())
}

export const getPartneredCasinos = () => {
    return [...casinos].filter((casino) => casino.isPartner)
}

export const getBonusCasinos = () => {
    const copy = [...casinos]

    copy.sort((a, b) => {
        return (b.bonus || 0) - (a.bonus || 0)
    })

    return copy
}

export const getAbroadCasinos = () => {
    const copy = [...casinos].filter((casino) => !casino.isLatvian)

    copy.sort((a, b) => {
        return a.isPartner === b.isPartner ? 0 : a.isPartner ? -1 : 1
    })

    return copy
}

export const getLatvianCasinos = () => {
    const copy = [...casinos].filter((casino) => casino.isLatvian)

    copy.sort((a, b) => {
        return a.isPartner === b.isPartner ? 0 : a.isPartner ? -1 : 1
    })

    return copy
}

const casinos: Casino[] = [
    {
        id: "verde",
        name: "Verde",
        referralUrl: "https://verdecasino-offers.com/l/65ddb12232405c8b320d231d",
        overviewUrl: "/kazino/verde/",
        logoUrl: "./assets/casinos/verde.png",
        backgroundColor: "#005d34",
        paymentMethods: ["visa", "mastercard", "jeton"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "USD", "CAD", "HUF", "BTC", "ETH"],
        missingGames: ["bingo"],
        pros: [
            "Plaša spēļu bibliotēka",
            "Ātras naudas izmaksas",
            "Draudzīgs klientu atbalsts",
            "Regulāras akcijas un bonusi",
            "Mobilajām ierīcēm optimizēta mājaslapa",
            "Droši un dažādi maksājumu veidi",
            "Licencēts un regulēts kazino",
        ],
        cons: ["Ierobežota pieejamība dažās valstīs", "Nav 24/7 tiešsaistes čata atbalsta", "Ierobežots dzīvo kazino spēļu klāsts"],
        bonus: 1200,
        bonusExtended: "150% bonuss līdz 1200€",
        freeSpins: 220,
        rating: 5,
        gamesCount: 5000,
        founded: 2022,
        isPartner: true,
    },
    {
        id: "myempire",
        name: "MyEmpire",
        referralUrl: "https://myemp.servclick1move.com/?mid=126037_886137",
        overviewUrl: "/kazino/myempire/",
        logoUrl: "./assets/casinos/myempire.png",
        backgroundColor: "#3f2419",
        paymentMethods: ["rapidTransfer", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "HUF", "NOK", "PLN", "INR", "NZD", "CAD", "USD", "BRL", "CZK", "AUD", "CLP", "PEN", "THB"],
        missingGames: ["sportsBetting"],
        pros: ["Plašs spēļu klāsts", "Ātra naudas izmaksa", "Izcils klientu atbalsts", "Mobilā spēlēšanas platforma"],
        cons: ["Lēna vietnes ielāde"],
        bonus: 800,
        bonusExtended: "120% bonuss līdz 800€",
        freeSpins: 300,
        rating: 5,
        gamesCount: 3000,
        founded: 2023,
        isPartner: true,
    },
    {
        id: "ice",
        name: "ICE",
        referralUrl: "https://icepromos.com/l/65f88eadb74fb0d8b205ff7b",
        overviewUrl: "/kazino/ice/",
        logoUrl: "./assets/casinos/ice.png",
        backgroundColor: "#ffffff",
        paymentMethods: ["visa", "mastercard", "trustly", "ecoPayz", "astropay", "mifinity", "rapidTransfer", "neteller", "skrill"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "BTC", "ETH", "USD"],
        missingGames: ["sportsBetting", "poker"],
        pros: [
            "Lieliska spēļu izvēle",
            "Ātras naudas izmaksas",
            "24/7 klientu atbalsts",
            "Pieņem kriptovalūtas",
            "Izdevīgi bonusi un akcijas",
            "Lietotājam draudzīga saskarne",
            "Dažādas valūtas un valodas",
        ],
        cons: ["Dažu valstu ierobežojumi", "Limitēti izmaksu limiti"],
        bonus: 1500,
        bonusExtended: "125% bonuss līdz 1500€",
        freeSpins: 270,
        rating: 5,
        gamesCount: 3500,
        founded: 2013,
        isPartner: true,
    },
    {
        id: "ggbet",
        name: "GGBet",
        referralUrl: "https://ggbetpromo.com/l/65d9edf1b508afd36905fda5",
        overviewUrl: "/kazino/ggbet/",
        logoUrl: "./assets/casinos/ggbet.png",
        backgroundColor: "#080808",
        paymentMethods: [
            "mastercard",
            "visa",
            "skrill",
            "ecoPayz",
            "paysafe",
            "jeton",
            "flexepin",
            "neosurf",
            "giropay",
            "interac",
            "interacE",
            "klarna",
        ],
        minDeposit: 10,
        minWithdraw: 20,
        currencies: ["EUR", "USD", "BTC", "ETH", "RUB"],
        missingGames: [],
        pros: [
            "Plašs spēļu klāsts",
            "Augsta drošības līmeņa nodrošināšana",
            "Ātras naudas iemaksas un izmaksas",
            "Izdevīgi bonussistēmas piedāvājumi",
            "24/7 klientu atbalsts",
            "Mobilajām ierīcēm optimizēta mājaslapa",
            "Daudzveidīgas maksājumu metodes",
        ],
        cons: ["Ierobežota pieejamība dažās valstīs", "Iespējamas izmaksas par līdzekļu izņemšanu", "Nepieejams dažu valstu spēlētājiem"],
        bonus: 1500,
        bonusExtended: "175% bonuss līdz 1500",
        freeSpins: 500,
        rating: 5,
        gamesCount: 5000,
        founded: 2016,
        isPartner: true,
    },
    {
        id: "drip",
        name: "Drip",
        referralUrl: "https://dripcasino.life/c8c1ae9ed",
        overviewUrl: "/kazino/drip/",
        logoUrl: "./assets/casinos/drip.png",
        backgroundColor: "#222525",
        paymentMethods: [
            "visa",
            "mastercard",
            "skrill",
            "neteller",
            "binance",
            "paysafe",
            "rapidTransfer",
            "bitcoin",
            "litecoin",
            "ethereum",
            "piastrix",
            "muchbetter",
        ],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "USD", "BTC", "ETH"],
        missingGames: [],
        pros: [
            "Plašs spēļu klāsts",
            "Ātra naudas izņemšana",
            "Draudzīgs klientu atbalsts",
            "Regulāri bonusi un akcijas",
            "Mobilajai ierīcei draudzīga vietne",
            "Drošības un konfidencialitātes garantija",
            "Vairāku valūtu atbalsts",
        ],
        cons: ["Ierobežojumi dažās valstīs", "Nav pieejama 24/7 tiešraides čata atbalsts", "Mazāk zināmu spēļu izstrādātāju trūkums"],
        bonus: Infinity,
        bonusExtended: "150% bezlimita bonuss!",
        freeSpins: 325,
        rating: 5,
        gamesCount: 5000,
        founded: 2023,
        isPartner: true,
    },
    {
        id: "spinsbro",
        name: "SpinsBro",
        referralUrl: "https://m.media13aff.com/Redirect.aspx?mid=148&sid=5804&cid=&pid=&affid=1331",
        overviewUrl: "/kazino/spinsbro/",
        logoUrl: "./assets/casinos/spinsbro.png",
        backgroundColor: "#f3b24d",
        paymentMethods: [
            "visa",
            "mastercard",
            "litecoin",
            "bitcoin",
            "ethereum",
            "mifinity",
            "ecoPayz",
            "muchbetter",
            "astropay",
            "revolut",
            "neosurf",
        ],
        minDeposit: 20,
        minWithdraw: 20,
        currencies: ["EUR", "BTC", "ETH", "LTC"],
        missingGames: ["sportsBetting"],
        pros: [
            "Vairāk nekā 7000 spēļu",
            "Ātra naudas izņemšana",
            "Atsaucīgs klientu serviss",
            "Pieejami dažādi bonusi",
            "Spēles no vadošajiem izstrādātājiem",
            "Draudzīga mobilā versija",
            "Vieglai navigācijai draudzīga vietne",
        ],
        cons: ["Ierobežojumi atsevišķās valstīs", "Var būt augsti izmaksas limiti"],
        bonus: 2120,
        bonusExtended: "490% bonuss līdz 2120€",
        freeSpins: 1000,
        rating: 5,
        gamesCount: 7000,
        founded: 2022,
        isPartner: true,
    },
    {
        id: "casombie",
        name: "Casombie",
        referralUrl: "https://media.casombie.partners/redirect.aspx?pid=7957&bid=1476",
        overviewUrl: "/kazino/casombie/",
        logoUrl: "./assets/casinos/casombie.png",
        backgroundColor: "#32127b",
        paymentMethods: [
            "visa",
            "mastercard",
            "postePay",
            "paysafe",
            "bankTransfer",
            "skrill",
            "neteller",
            "klarna",
            "mifinity",
            "astropay",
            "bitcoin",
            "litecoin",
        ],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "USD", "BTC"],
        missingGames: ["sportsBetting"],
        pros: [
            "Unikāla zombiju tematika",
            "Vairāk nekā 1000 spēles",
            "24/7 klientu atbalsts",
            "Pievilcīgi bonusa piedāvājumi",
            "Drošs un godīgs kazino",
            "Ātras izmaksas",
            "Liela spēļu izvēle",
        ],
        cons: ["Var apjukt jauni spēlētāji", "Ierobežojumi dažās valstīs", "Iespējama garāka verifikācija"],
        bonus: 1000,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 7000,
        founded: 2021,
        isPartner: true,
    },
    {
        id: "boomerang",
        name: "Boomerang",
        referralUrl: "https://boomerang11.com/?serial=9616&creative_id=11&anid=",
        overviewUrl: "/kazino/boomerang/",
        logoUrl: "./assets/casinos/boomerang.png",
        backgroundColor: "#090e12",
        paymentMethods: [
            "visa",
            "mastercard",
            "postePay",
            "skrill",
            "neteller",
            "interac",
            "mifinity",
            "bankTransfer",
            "paysafe",
            "bitcoin",
            "litecoin",
            "ethereum",
            "ripple",
        ],
        minDeposit: 10,
        minWithdraw: 20,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "JPY",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "TRY",
            "ZAR",
            "COP",
            "SGD",
            "DKK",
        ],
        missingGames: [],
        pros: [
            "Plašs spēļu klāsts",
            "Ātras naudas izmaksas",
            "Draudzīgs klientu atbalsts",
            "Regulāras akcijas un bonusi",
            "Mūsdienīgs mājaslapas dizains",
            "Drošības un licencēšanas garantijas",
            "Ir mobilā aplikācija",
        ],
        cons: ["Ierobežojumi dažās valstīs", "Mājaslapa ir grūti apskatāma telefona pārlūkā", "Ierobežots live kazino klāsts"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 7000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "5gringos",
        name: "5Gringos",
        referralUrl: "https://fgr.servclick1move.com/?mid=126033_895977",
        overviewUrl: "/kazino/5gringos/",
        logoUrl: "./assets/casinos/5gringos.png",
        backgroundColor: "#2a1420",
        paymentMethods: ["visa", "mastercard", "rapidTransfer", "bankTransfer", "skrill", "paysafe", "neteller", "mifinity", "jeton"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "CLP",
            "PEN",
            "CHF",
            "TRY",
            "THB",
            "NGN",
            "ZAR",
            "MXN",
            "SGD",
            "TND",
            "MYR",
            "PHP",
            "KRW",
            "KZT",
        ],
        missingGames: ["sportsBetting"],
        pros: [
            "Plašs spēļu klāsts",
            "Ātra izņemšana",
            "Draudzīgs klientu atbalsts",
            "Aizraujoši bonusi un akcijas",
            "Mobilajai ierīcei draudzīgs dizains",
            "Drošības un licences garantija",
            "Daudzveidīgas maksājumu iespējas",
        ],
        cons: ["Ierobežojumi dažās valstīs", "Nav pieejams 24/7 klientu atbalsts", "Ierobežots galda spēļu klāsts"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 4500,
        founded: 2021,
        isPartner: true,
    },
    {
        id: "sportaza",
        name: "Sportaza",
        referralUrl: "https://stz.servclick1move.com/?mid=126033_895985",
        overviewUrl: "/kazino/sportaza/",
        logoUrl: "./assets/casinos/sportaza.png",
        backgroundColor: "#061249",
        paymentMethods: ["visa", "mastercard", "rapidTransfer", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "TRY",
            "THB",
            "NGN",
            "ZAR",
            "MXN",
            "SGD",
            "MYR",
            "PHP",
            "KRW",
        ],
        missingGames: [],
        pros: [
            "Plašs sporta derību klāsts",
            "Bagātīgs kazino spēļu portfelis",
            "Aizraujoši bonusi un akcijas",
            "Ērta un lietotājdraudzīga saskarne",
            "Daudzvalodu atbalsts klientiem",
            "Ātra un droša naudas izņemšana",
            "Mobilā aplikācija pieejama",
        ],
        cons: ["Ierobežojumi dažās valstīs", "Iespējama izņemšanas komisija", "Atbalsta dienests ne vienmēr ir ātrs"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 4500,
        founded: 2021,
        isPartner: true,
    },
    {
        id: "posido",
        name: "Posido",
        referralUrl: "https://psdcur.servclick1move.com/?mid=126033_895957",
        overviewUrl: "/kazino/posido/",
        logoUrl: "./assets/casinos/posido.png",
        backgroundColor: "#013d8f",
        paymentMethods: ["visa", "mastercard", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "JPY",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "TRY",
            "THB",
            "NGN",
            "ZAR",
            "MXN",
            "COP",
            "SGD",
            "TND",
            "IDR",
            "MYR",
            "VND",
            "PHP",
            "KRW",
            "HKD",
            "KZT",
            "AZN",
        ],
        missingGames: [],
        pros: [
            "Moderns dizains un lietotājdraudzīga saite",
            "Dāsna bonusu programma un akcijas",
            "Augsta drošības līmeņa datu aizsardzība",
            "Daudzvalodu klientu atbalsts",
            "Pieejama mobilā aplikācija",
            "Ātras un drošas maksājumu iespējas",
        ],
        cons: ["Ierobežots spēļu izstrādātāju klāsts", "Var būt ierobežojumi dažām valstīm", "Atbalsta dienesta atbildes var aizkavēties"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 2000,
        founded: 2022,
        isPartner: true,
    },
    {
        id: "frumzi",
        name: "Frumzi",
        referralUrl: "https://frm.servclick1move.com/?mid=126033_895945",
        overviewUrl: "/kazino/frumzi/",
        logoUrl: "./assets/casinos/frumzi.png",
        backgroundColor: "#0b0833",
        paymentMethods: ["visa", "mastercard", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "HUF", "NOK", "PLN", "INR", "NZD", "USD", "BRL", "CLP", "PEN"],
        missingGames: [],
        pros: [
            "Nav nepieciešama reģistrācija",
            "Ātra naudas izmaksa",
            "Plašs kazino spēļu klāsts",
            "Vadošo spēļu izstrādātāju piedāvājumi",
            "Draudzīgs un atsaucīgs klientu atbalsts",
            "Mobilajai ierīcei optimizēts",
            "Drošas un daudzveidīgas maksājumu metodes",
        ],
        cons: ["Pieejamība var atšķirties atkarībā no valsts"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 7900,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "7signs",
        name: "7Signs",
        referralUrl: "https://sign.servclick1move.com/?mid=126033_895981",
        overviewUrl: "/kazino/7signs/",
        logoUrl: "./assets/casinos/7signs.png",
        backgroundColor: "#ff5602",
        paymentMethods: ["visa", "mastercard", "skrill"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "TRY",
            "THB",
            "NGN",
            "ZAR",
            "MXN",
            "SGD",
            "TND",
            "KZT",
        ],
        missingGames: ["sportsBetting"],
        pros: [
            "7 unikāli bonuss piedāvājumi",
            "Plašs spēļu klāsts no vadošajiem izstrādātājiem",
            "Ātras un drošas maksājumu iespējas",
            "Regulāras akcijas un lojalitātes programma",
            "Daudzvalodu atbalsts",
        ],
        cons: ["Nav pieejams sports betting", "Var būt ierobežojumi atsevišķās valstīs"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 4000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "amunra",
        name: "AmunRa",
        referralUrl: "https://amr.servclick1move.com/?mid=126033_895941",
        overviewUrl: "/kazino/amunra/",
        logoUrl: "./assets/casinos/amunra.png",
        backgroundColor: "#643b20",
        paymentMethods: ["visa", "mastercard", "rapidTransfer", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "THB",
            "NGN",
            "ZAR",
            "SGD",
            "IDR",
            "MYR",
            "PHP",
            "KRW",
        ],
        missingGames: [],
        pros: [
            "Unikāla Ēģiptes tematika",
            "Plašs spēļu klāsts no vadošajiem izstrādātājiem",
            "Ģenerozi bonusi un akcijas",
            "24/7 klientu atbalsts",
            "Ātras maksājumu metodes",
        ],
        cons: ["Mazāk iespēju zemu likmju spēlētājiem"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 2000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "neon54",
        name: "Neon54",
        referralUrl: "https://n54.servclick1move.com/?mid=126037_884601",
        overviewUrl: "/kazino/neon54/",
        logoUrl: "./assets/casinos/neon54.png",
        backgroundColor: "#a1ece1",
        paymentMethods: ["mastercard", "rapidTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity", "jeton"],
        minDeposit: 20,
        minWithdraw: 20,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "JPY",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "NGN",
            "ZAR",
            "SGD",
            "MYR",
            "PHP",
            "KRW",
        ],
        missingGames: ["sportsBetting"],
        pros: ["Moderns dizains", "Daudz bonusu piedāvājumu", "24/7 klientu atbalsts"],
        cons: ["Ierobežots bonusu piedāvājums"],
        bonus: 500,
        bonusExtended: "100% bonuss līdz 500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 4000,
        founded: 2021,
        isPartner: true,
    },
    {
        id: "slotspalace",
        name: "SlotsPalace",
        referralUrl: "https://slp.servclick1move.com/?mid=126037_886125",
        overviewUrl: "/kazino/slotspalace/",
        logoUrl: "./assets/casinos/slotspalace.png",
        backgroundColor: "#4f338b",
        paymentMethods: ["mastercard", "rapidTransfer", "bankTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "mifinity"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: [
            "EUR",
            "HUF",
            "NOK",
            "PLN",
            "INR",
            "NZD",
            "CAD",
            "USD",
            "BRL",
            "CZK",
            "JPY",
            "AUD",
            "CLP",
            "PEN",
            "CHF",
            "THB",
            "NGN",
            "ZAR",
            "SGD",
            "MYR",
            "PHP",
            "KRW",
        ],
        missingGames: [],
        pros: ["Lieliska spēļu izvēle - gandrīz 6000 spēles", "Pievilcīgs un moderns dizains", "Daudzveidīgi bonusi un akcijas"],
        cons: ["Sarežģīti bonusu noteikumi", "Nav Eiropas Savienības licences"],
        bonus: 1000,
        bonusExtended: "100% bonuss līdz 1000€",
        rating: 5,
        gamesCount: 5000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "betinia",
        name: "Betinia",
        referralUrl: "https://btn.servclick1move.com/?mid=126041_884589",
        overviewUrl: "/kazino/betinia/",
        logoUrl: "./assets/casinos/betinia.png",
        backgroundColor: "#151515",
        paymentMethods: ["mastercard", "rapidTransfer", "skrill", "paysafe", "neteller", "ezeeWallet", "jeton"],
        minDeposit: 10,
        minWithdraw: 10,
        currencies: ["EUR", "HUF", "NOK", "PLN", "INR", "NZD", "USD", "BRL"],
        missingGames: [],
        pros: [
            "Plašs spēļu klāsts",
            "Augsta drošības līmenis",
            "Ātra izņemšanas procedūra",
            "Draudzīgs klientu atbalsts",
            "Regulāras akcijas un bonusi",
            "Pieejama mobilā aplikācija",
            "Daudzas maksājumu metodes",
        ],
        cons: ["Ierobežojumi dažās valstīs", "Var būt augstas likmju prasības", "Nav pieejams 24/7 tiešraides čats"],
        bonus: 1000,
        bonusExtended: "100% bonuss līdz 1000€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 2000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "cosmicslot",
        name: "CosmicSlot",
        referralUrl: "https://m.media13aff.com/Redirect.aspx?mid=12&sid=5804&cid=&pid=&affid=1331",
        overviewUrl: "/kazino/cosmicslot/",
        logoUrl: "./assets/casinos/cosmicslot.png",
        backgroundColor: "#000124",
        paymentMethods: [
            "visa",
            "mastercard",
            "trustly",
            "muchbetter",
            "jeton",
            "ethereum",
            "litecoin",
            "bitcoin",
            "piastrix",
            "neteller",
            "skrill",
            "revolut",
        ],
        minDeposit: 20,
        minWithdraw: 20,
        currencies: ["EUR", "BTC", "ETH", "LTC"],
        missingGames: ["sportsBetting"],
        pros: [
            "Vairāk nekā 6000 spēļu",
            "Ātras izmaksas 24 stundu laikā",
            "Draudzīgs un atsaucīgs atbalsts",
            "Lieliski bonusi jauniem spēlētājiem",
            "Regulāras akcijas un turnīri",
            "Pieņem kriptovalūtu",
            "Plašs dzīvo spēļu klāsts",
        ],
        cons: ["Dažu valstu ierobežojumi", "Var būt augstas likmju prasības"],
        bonus: 3500,
        bonusExtended: "252% bonuss līdz 3500€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 6000,
        founded: 2020,
        isPartner: true,
    },
    {
        id: "lvbet",
        name: "LV BET",
        referralUrl: "https://m.lvbetpartners.lv/Redirect.aspx?mid=208&sid=1429&cid=&pid=&affid=1243",
        overviewUrl: "/kazino/lvbet/",
        logoUrl: "./assets/casinos/lvbet.png",
        backgroundColor: "#000000",
        paymentMethods: ["visa", "mastercard", "revolut"],
        minDeposit: 5,
        minWithdraw: 10,
        currencies: ["EUR", "USD"],
        missingGames: [],
        pros: ["Kazino Latviešu valodā", "Liela live kazino izvēle", "Viegli pārskatāma lietotne"],
        cons: ["Nav VIP programmas", "Limiti izmaksām"],
        bonusExtended: "Bez bonusa",
        freeSpins: 500,
        rating: 5,
        gamesCount: 400,
        founded: 2020,
        isLatvian: true,
        isPartner: true,
    },
    {
        id: "optibet",
        name: "Optibet",
        referralUrl: "https://record.enlabspartners.com/_0nhzToqAaBhoofBpZ-qTpmNd7ZgqdRLk/1",
        overviewUrl: "/kazino/optibet/",
        logoUrl: "./assets/casinos/optibet.png",
        backgroundColor: "#101b36",
        paymentMethods: ["visa", "mastercard", "revolut"],
        minDeposit: 5,
        minWithdraw: 5,
        currencies: ["EUR"],
        missingGames: [],
        pros: [
            "Iespaidīgs sporta totalizators ar plašu iespēju klāstu",
            "Live kazino sadaļa ar klasiskajām kazino spēlēm un jauniem spēļu šoviem",
            "Īpaša pokera sadaļa",
        ],
        cons: ["Daži bonusi ir speciāli jāaktivizē konta sadaļā “Mans bonuss”, tie neaktivizējas automātiski", "Kazino nepieņem kriptovalūtas"],
        bonus: 100,
        bonusExtended: "100% bonuss līdz 100€",
        freeSpins: 100,
        rating: 5,
        gamesCount: 1200,
        founded: 2015,
        isLatvian: true,
        isPartner: true,
    },
    {
        id: "laimz",
        name: "Laimz",
        referralUrl: "https://record.enlabspartners.com/_0nhzToqAaBgQGAC4SzYtmGNd7ZgqdRLk/1",
        overviewUrl: "/kazino/laimz/",
        logoUrl: "./assets/casinos/laimz.png",
        backgroundColor: "#6342b4",
        paymentMethods: ["visa", "mastercard", "revolut"],
        minDeposit: 5,
        minWithdraw: 5,
        currencies: ["EUR"],
        missingGames: ["sportsBetting"],
        pros: ["Jauns kazino", "Plašs klāsts", "Ātra reģistrācija", "Latviešu valoda", "Moderna mājaslapa", "Mobila lietotne"],
        cons: ["Nav VIP programmas", "Limitēts info maksājumiem"],
        bonus: 200,
        bonusExtended: "100% bonuss līdz 200€",
        freeSpins: 200,
        rating: 5,
        gamesCount: 2500,
        founded: 2020,
        isLatvian: true,
        isPartner: true,
    },
    {
        id: "klondaika",
        name: "Klondaika",
        referralUrl: "https://record.enlabspartners.com/_0nhzToqAaBjVxU5wQ-_6GGNd7ZgqdRLk/1",
        overviewUrl: "/kazino/klondaika/",
        logoUrl: "./assets/casinos/klondaika.png",
        backgroundColor: "#0f51ca",
        paymentMethods: ["visa", "mastercard", "revolut"],
        minDeposit: 5,
        minWithdraw: 5,
        currencies: ["EUR"],
        missingGames: [],
        pros: ["Plašs spēļu klāsts", "Live kazino izpriecas", "Totalizators", "Lojalitātes programma", "Latviešu valodas atbalsts", "Droša spēļu vide"],
        cons: ["Nav VIP programmas"],
        bonusExtended: "Bez bonusa",
        freeSpins: 400,
        rating: 5,
        gamesCount: 1000,
        founded: 1993,
        isLatvian: true,
        isPartner: true,
    },
]
