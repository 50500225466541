import { Col, Container, Row } from "react-bootstrap"
import "./SlotGrid.css"
import { SlotCard } from "../slotCard/SlotCard"
import { Slot } from "../../slotData"

interface SlotGridProps {
    slotData: Slot[]
}

export const SlotGrid = (props: SlotGridProps) => {
    const { slotData } = props

    return (
        <Container className="slotGridContainer">
            <Row className="justify-content-center">
                {slotData.map((slot) => {
                    return (
                        <Col className="pb-4" xs={6} sm={4} md={3} lg={2} xl={2} key={slot.id}>
                            <SlotCard slot={slot} />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}
