import { Info } from "../../components/info/Info"

export const Klondaika = () => {
    return (
        <>
            <Info secondary className="pb-5">
                <h2>🌟 Mūsu viedoklis par Klondaika</h2>
                <p>
                    Klondaika kazino ir labi pazīstams Latvijas spēlētājiem, pateicoties tā plašajam kazino spēļu klāstam un ilggadējai pieredzei.
                    Spēlētāji var izvēlēties no populāriem spēļu automātiem, klasiskām galda spēlēm, aizraujošām tiešraides kazino spēlēm un pat
                    totalizatora sadaļas. Klondaika piedāvā arī pievilcīgus bonusus jauniem spēlētājiem un lojalitātes programmu pastāvīgajiem
                    klientiem. Papildus plašajam spēļu klāstam, Klondaika uzsver drošu spēļu vidi un profesionālu klientu atbalstu latviešu valodā.
                </p>
            </Info>
        </>
    )
}
