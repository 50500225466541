import { Container, Tab, Tabs } from "react-bootstrap"
import { SlotGrid } from "../../components/slotGrid/SlotGrid"
import { getNewSlots, getPopularSlots, getSlots } from "../../slotData"
import { Info } from "../../components/info/Info"
import { Helmet } from "react-helmet"

export const Slots = () => {
    return (
        <>
            <Helmet>
                <title>Spēlē Spēļu Automātu Demo Versijas un Saņem Bonusus 🎖️ 2024</title>
                <meta
                    name="description"
                    content="Izmēģini spēļu automātu bezmaksas versijas un lasi mūsu atsauksmes. Atklāj 2024. gada labākos bonusus un piedāvājumus!"
                />
            </Helmet>
            <Container>
                <h1>Izmēģini jaunākos un populārākos slotus!</h1>
                <p>
                    Bezmaksas kazino spēles ir spēļu automāti, rulete, blekdžeks, bakara, blekdžeks un citas azartspēles, kas internetā pieejamas par
                    brīvu ikkatram spēlētājam. Tās paredzētas, lai ikviens interesents varētu iepazīties ar jaunām spēlēm un izbaudīt to sniegto azartu,
                    neriskējot ar saviem naudas līdzekļiem. Spēlēšanu bezmaksas izbauda gan jaunie, gan pieredzējušie spēlētāji! Un brīdī, kad esi
                    gatavs spēlēt uz īstu naudu, vari izvēlēties kādu no mūsu piedāvātajiem ārzemju kazino!
                </p>
                <p>
                    Kamēr bezmaksas spēļu automāti ir pieejami teju visos ārzemju interneta kazino, tos var uzspēlēt arī pie mums - vietnē
                    kazinounkazino.com! Uzskatām, ka tā ir lieliska iespēja, kā izstrādāt stratēģijas un iepazīties ar spēles gaitu, pirms riskē uz īstu
                    naudu. Ja esi azartspēlmanis, tad Tev noteikti jāizmēģina bezmaksas kazino spēles un spēļu automāti bez reģistrācijas!
                </p>
                <Tabs
                    justify
                    variant="pills"
                    defaultActiveKey={"visiSloti"}
                    className="mb-3 gap-2 p-3 rounded"
                    style={{ backgroundColor: "rgba(40, 40, 40, 0.5)" }}
                >
                    <Tab eventKey="visiSloti" title="Visi sloti">
                        <SlotGrid slotData={getSlots()} />
                    </Tab>
                    <Tab eventKey="popularakieSloti" title="Populārākie sloti">
                        <SlotGrid slotData={getPopularSlots()} />
                    </Tab>
                    <Tab eventKey="jaunakieSloti" title="Jaunākie sloti">
                        <SlotGrid slotData={getNewSlots()} />
                    </Tab>
                </Tabs>
            </Container>
            <Info>
                <h2>Latvijā labākās un populārākās slotu spēles</h2>
                <ol>
                    <li>
                        <strong>Book of Ra</strong>
                        <p>
                            Book of Ra ir viens no ikoniskākajiem spēļu automātiem no spēļu izstrādātāja Novomatic. Šis spēļu automāts piedāvā
                            aizraujošu eģiptiešu tēmu un aizraujošas bonusa funkcijas, tostarp bezmaksas griezienus un paplašināšanos simbolus.
                        </p>
                    </li>

                    <li>
                        <strong>Starburst</strong>
                        <p>
                            Starburst ir viens no vispopulārākajiem spēļu automātiem no spēļu izstrādātāja NetEnt. Šis spēļu automāts izceļas ar savu
                            spilgtu un krāsainu dizainu, kā arī vienkāršu, bet pievilcīgu spēles mehānismu ar savienojamo simbolu funkciju un bezmaksas
                            griezieniem.
                        </p>
                    </li>

                    <li>
                        <strong>Gonzo's Quest</strong>
                        <p>
                            Gonzo's Quest ir piedzīvojis milzīgu popularitāti kopš tā iznākšanas. Šis NetEnt spēļu automāts piedāvā aizraujošu
                            piedzīvojumu džungļos, kur galvenais varonis, Gonzo, cenšas atrast slaveno Eldorado zeltu. Spēlētāji var izbaudīt kaskādes
                            funkciju un bezmaksas griezienus ar palielinājošiem laimestiem.
                        </p>
                    </li>

                    <li>
                        <strong>Mega Moolah</strong>
                        <p>
                            Mega Moolah ir viens no visvairāk zināmajiem progresīvo džekpotu spēļu automātiem. Šis Microgaming spēļu automāts piedāvā
                            iespēju laimēt milzīgus džekpotus, un tas ir iecienīts spēlētāju vidū ar savu izdevīgo džekpotu funkciju un aizraujošo zvēru
                            tēmu.
                        </p>
                    </li>
                </ol>

                <p>
                    Izbaudi šīs un citas aizraujošas slotu spēles un iegūsti lieliskas izklaides un uzvaras piedzīvojumus{" "}
                    <a href="https://www.lvbetkazino.lv/">Latvijas online kazino!</a>
                </p>
            </Info>
        </>
    )
}
