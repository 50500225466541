import { Button, Col, Row } from "react-bootstrap"
import { Casino } from "../../casinoData"
import "./CasinoBanner.css"
import { DataBox } from "../dataBox/DataBox"
import classNames from "classnames"
import ReactGA from "react-ga4"

interface CasinoBannerProps {
    casino: Casino
    theme?: "dark" | "light"
    showOverview?: boolean
}

export const CasinoBanner = (props: CasinoBannerProps) => {
    const { casino, theme, showOverview = true } = props

    const onClickBonus = () => {
        ReactGA.event({
            category: "Promotion",
            action: "clicked_bonus",
            label: casino.id,
            value: 1,
        })
    }

    return (
        <Row
            className={classNames("bannerContainer p-2 mt-2 rounded", { lightTheme: theme === "light" })}
            itemScope
            itemType="https://schema.org/Casino"
        >
            <Col xs={12} md={2} className="logoContainer order-1 rounded" style={{ backgroundColor: casino.backgroundColor }}>
                <a
                    className="logo d-flex justify-content-center align-items-center rounded"
                    href={showOverview ? casino.overviewUrl : undefined}
                    itemProp="url"
                >
                    <img alt={casino.name} src={casino.logoUrl} itemProp="image" />
                </a>
            </Col>
            <Col className="d-flex flex-column justify-content-sm-center order-3 order-md-2">
                {casino.bonus && (
                    <Row>
                        <Col className="bonus" itemProp="bonus">
                            <h5 className="m-0">
                                <strong>{casino.bonusExtended}</strong>
                            </h5>
                        </Col>
                    </Row>
                )}
                {casino.freeSpins && (
                    <Row>
                        <Col className="freeSpins" itemProp="freeSpins">
                            {casino.bonus && <h6 className="m-0">{`+ ${casino.freeSpins} bezriska griezieni`}</h6>}
                            {!casino.bonus && <h5 className="m-0">{`${casino.freeSpins} bezriska griezieni`}</h5>}
                        </Col>
                    </Row>
                )}
            </Col>

            <Col sm={12} md={4} className="d-flex flex-column justify-content-center order-2 order-md-3 px-0 py-2 px-md-2 py-md-0">
                <Row className="p-0 gx-2">
                    <Col xs={3} md={6} itemProp="minimumDeposit">
                        <DataBox mainText={casino.minDeposit + "€"} comment="Min. iemaksa" />
                    </Col>
                    <Col xs={3} md={6} itemProp="minimumWithdrawal">
                        <DataBox mainText={casino.minWithdraw + "€"} comment="Min. izmaksa" />
                    </Col>
                    <Col xs={3} md={6} className="mt-md-2" itemProp="games">
                        <DataBox mainText={casino.gamesCount + "+"} comment="Spēles" />
                    </Col>
                    <Col xs={3} md={6} className="mt-md-2" itemProp="founded">
                        <DataBox mainText={casino.founded.toString()} comment="Dibināts" />
                    </Col>
                </Row>
            </Col>

            <Col xs={6} md={3} lg={2} className="order-4 p-0 p-sm-2 d-flex flex-column justify-content-md-center align-items-center">
                <Button
                    variant={casino.isPartner ? "success" : "secondary"}
                    className="w-100 h-50 d-flex justify-content-center align-items-center flex-grow-1"
                    href={`/go/${casino.id}/`}
                    rel="nofollow"
                    onClick={onClickBonus}
                    itemProp="address"
                >
                    <strong>{casino.isPartner ? "Saņemt bonusu" : "Apmeklēt kazino"}</strong>
                </Button>
                {showOverview && (
                    <a className="overviewLink pt-2" href={casino.overviewUrl} itemProp="url">
                        <img alt="info" className="align-middle me-1" src="./assets/icons/info.svg" />
                        <span className="text-center">{`Vairāk par ${casino.name}`}</span>
                    </a>
                )}
            </Col>
        </Row>
    )
}
