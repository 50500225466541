import { Button, Col, Container, Row } from "react-bootstrap"
import { Casino } from "../../casinoData"
import "./StickyCasinoBanner.css"
import { useEffect, useState } from "react"
import classNames from "classnames"

interface StickyCasinoBannerProps {
    casino: Casino
}

export const StickyCasinoBanner = (props: StickyCasinoBannerProps) => {
    const { casino } = props
    const [show, setShow] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            const position = window.scrollY
            const maxPosition = window.document.documentElement.scrollHeight - window.innerHeight
            const buffer = maxPosition * 0.1

            if (position > buffer && position < maxPosition - buffer) {
                setShow(true)
            } else {
                setShow(false)
            }
        }

        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <div className={classNames("stickyCasinoBannerContainer position-fixed w-100 bottom-0", { isHidden: !show })}>
            <Container>
                <Row>
                    <Col
                        xs={2}
                        className="d-flex justify-content-center align-items-center rounded m-1 p-0 p-sm-1"
                        style={{ backgroundColor: casino.backgroundColor }}
                    >
                        <img
                            alt={casino.name}
                            src={casino.logoUrl}
                            style={{ objectFit: "contain", height: "100%", maxHeight: "5rem", maxWidth: "90%" }}
                        />
                    </Col>
                    <Col className="d-flex flex-column justify-content-center py-3 py-sm-2 ps-1">
                        {casino.bonus && (
                            <span className="m-0">
                                <strong style={{ color: "white" }}>{casino.bonusExtended}</strong>
                            </span>
                        )}
                        {casino.bonus && casino.freeSpins && (
                            <small className="m-0" style={{ color: "white" }}>{`+ ${casino.freeSpins} bezriska griezieni`}</small>
                        )}
                        {!casino.bonus && <span className="m-0" style={{ color: "white" }}>{`${casino.freeSpins} bezriska griezieni`}</span>}
                    </Col>
                    <Col xs={2} md={2} lg={2} xl={1} className="p-0 p-sm-1">
                        <Button
                            className="redirectButton d-flex justify-content-center align-items-center w-100 h-100"
                            variant="success"
                            href={`/go/${casino.id}/`}
                            rel="nofollow"
                        >
                            <strong className="fs-5">{"►"}</strong>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
